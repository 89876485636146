import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const ListHead = (props) => {
  const { classes, order, orderBy, onRequestSort, heads } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heads.map((head) => {
          if (head.label != "Id") {
            return (
              <TableCell
                key={head.id}
                align={head.numeric ? "right" : "left"}
                padding={head.disablePadding ? "none" : "default"}
                sortDirection={orderBy === head.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === head.id}
                  direction={orderBy === head.id ? order : "asc"}
                  onClick={createSortHandler(head.id)}
                >
                  {head.label === "Profile Icon" ? null : head.label}
                  {orderBy === head.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

ListHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default ListHead;
