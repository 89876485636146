import {
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  RESET_USER_STATE,
} from "../../actions/types";

const initialState = {
  users: null,
  usersCount: null,
  user: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.user,
        usersCount: payload.count,
        loading: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case RESET_USER_STATE:
      return {
        users: null,
        user: null,
        usersCount: null,
        loading: true,
      };
    default:
      return state;
  }
}
