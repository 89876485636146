import axios from "axios";

import { SEARCH_SUCCESS } from "../types";

import { setAlert } from "../alert/alert";

export const textSearch = (on, text, page, size, status = "") => async (
  dispatch
) => {
  try {
    const response = await axios.get(
      `/api/search/${on}/${page}/${size}?text=${text}&status=${status}`
    );
    dispatch({
      type: SEARCH_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log(err.response);
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};
