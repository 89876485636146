import React, { useRef, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FileIcon, { defaultStyles } from "react-file-icon";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fileUpload, resetUploadState } from "../../../actions/upload/upload";
import { editConsult, getCreatedFor } from "../../../actions/consult/consult";
import { getAllTemplate } from "../../../actions/template/template";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
  button: {
    marginTop: theme.spacing(1),
  },
  textEditorError: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    borderColor: "red",
  },
}));

const CreateConsult = ({
  responseIndex,
  fileUpload,
  consultId,
  upload,
  alert,
  showPage,
  editConsult,
  getCreatedFor,
  getAllTemplate,
  resetUploadState,
  template: { templates },
  consult: { consultsType },
}) => {
  const classes = useStyles();

  const [buttonDisable, setButtonDisable] = useState(false);

  const setPageAtSession = () => {
    sessionStorage.setItem("page", 0);
    sessionStorage.setItem("rowsPerPage", 10);
  };

  useEffect(() => {
    if (alert && alert[0] && alert[0].msg == "Consult Edited Successfully") {
      setPageAtSession();
      resetUploadState();
      getCreatedFor(consultsType, 1, 10);
      showPage("ConsultTable");
    } else {
      setButtonDisable(false);
    }
  }, [alert]);

  useEffect(() => {
    getAllTemplate(1, 100);
  }, [getAllTemplate]);

  const [attachmentsUrls, setAttachmentsUrls] = useState([]);

  useEffect(() => {
    upload.url &&
      upload.url.length >= 0 &&
      setAttachmentsUrls((prevState) => [...prevState, upload.url[0]]);
    console.log(attachmentsUrls);
  }, [upload]);

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      responseAttachments: [{ name: "", url: "" }],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "responseAttachments",
  });

  const filePickerRef = useRef([]);

  const pickedHandler = (event) => {
    if (event.target.files && event.target.files.length) {
      const formData = new FormData();
      formData.append("attachments", event.target.files[0]);
      fileUpload(formData);
    }
  };

  const pickImageHandler = (index) => {
    filePickerRef &&
      filePickerRef.current[index] &&
      filePickerRef.current[index].click();
  };

  const getFileExtension = (url) => {
    const fileRegex = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined;
    return fileRegex[0];
  };

  const removeAttachments = (index) => {
    remove(index);
    attachmentsUrls.splice(index, 1);
  };

  const [editorStateResponse, setEditorStateResponse] = useState("");

  const onSubmit = (data) => {
    if (validateConsult()) {
      setButtonDisable(true);
      if (responseIndex == 1) {
        data.status = "RESP";
      }
      if (responseIndex == 2) {
        data.status = "FRES";
      }
      console.log(data);
      let responsekey = `consultations.${responseIndex - 1}.response`;
      let respondedonkey = `consultations.${responseIndex - 1}.respondedOn`;
      let responseAttachmentkey = `consultations.${
        responseIndex - 1
      }.responseAttachments`;

      let formData =
        attachmentsUrls && attachmentsUrls.length > 0
          ? {
              [responsekey]: data.response,
              [responseAttachmentkey]: data.responseAttachments.filter(
                (attachment) => attachment.url != ""
              ),
              [respondedonkey]: data.respondedOn,
              status: data.status,
            }
          : {
              [responsekey]: data.response,
              [respondedonkey]: data.respondedOn,
              status: data.status,
            };
      console.log(formData);
      editConsult(formData, consultId);
    }
  };
  const validateConsult = () => {
    let consult = getValues("response");
    if (!consult.replace(/(<([^>]+)>)/gi, "")) {
      setError("response", {
        type: "validate",
        message: "Response is required",
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Response:</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="title"
                  label="Templates"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setEditorStateResponse("");
                    e &&
                      e.target &&
                      e.target.value &&
                      e.target.value != "select" &&
                      setEditorStateResponse(e.target.value);
                  }}
                >
                  <option value="select">Select Response From Templates</option>
                  {templates &&
                    templates.map((template) => (
                      <option key={template._id} value={template.data}>
                        {template.title}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="response"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.response}
                  inputRef={register({ required: true })}
                />
                <ReactQuill
                  theme="snow"
                  className={errors.response && classes.textEditorError}
                  value={editorStateResponse}
                  onChange={(value) => {
                    setEditorStateResponse(value);
                    clearErrors("response");
                  }}
                />
                {errors.response && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.response.message}
                  </Typography>
                )}
              </Grid>
              <input
                name="respondedOn"
                ref={register}
                style={{ display: "none" }}
                type="text"
                value={Date.now()}
              />
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Upload Attachments</TableCell>
                      <TableCell>File Icon</TableCell>
                      <TableCell colSpan="2">Attachment Name</TableCell>
                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <input
                              ref={(el) => (filePickerRef.current[index] = el)}
                              style={{ display: "none" }}
                              type="file"
                              accept=""
                              onChange={pickedHandler}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => pickImageHandler(index)}
                              disabled={attachmentsUrls[index]}
                            >
                              Upload
                            </Button>
                          </TableCell>
                          <TableCell>
                            {attachmentsUrls[index] && (
                              <FileIcon
                                size={50}
                                extension={getFileExtension(
                                  attachmentsUrls[index]
                                )}
                                {...defaultStyles[
                                  getFileExtension(attachmentsUrls[index])
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`responseAttachments[${index}].name`}
                              style={{
                                display: attachmentsUrls[index]
                                  ? "block"
                                  : "none",
                              }}
                              label="Attachment Name *"
                              placeholder="Attachment Name"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              error={
                                errors.responseAttachments &&
                                errors.responseAttachments[index] &&
                                errors.responseAttachments[index].name
                              }
                              inputRef={register({
                                required: {
                                  value: attachmentsUrls[index] ? true : false,
                                  message: "Attachment Name is required",
                                },
                              })}
                            />
                            {errors.responseAttachments &&
                              errors.responseAttachments[index] &&
                              errors.responseAttachments[index].name && (
                                <Typography
                                  variant="h6"
                                  className={classes.errorText}
                                >
                                  {
                                    errors.responseAttachments[index].name
                                      .message
                                  }
                                </Typography>
                              )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`responseAttachments[${index}].url`}
                              style={{ display: "none" }}
                              label="Attachment URL"
                              placeholder="Attachment URL"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              value={
                                attachmentsUrls[index]
                                  ? attachmentsUrls[index]
                                  : ""
                              }
                              inputRef={register()}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => removeAttachments(index)}
                              color="primary"
                              variant="contained"
                            >
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Button
                          onClick={() => {
                            append({ name: "", url: "" });
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Add More Attachments
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={buttonDisable}
                  onClick={() => setValue("response", editorStateResponse)}
                >
                  Response Consultation
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

CreateConsult.propTypes = {
  upload: PropTypes.object.isRequired,
  editConsult: PropTypes.func.isRequired,
  getCreatedFor: PropTypes.func.isRequired,
  fileUpload: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  getAllTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  resetUploadState: PropTypes.func.isRequired,
  consult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  upload: state.upload,
  alert: state.alert,
  template: state.template,
  consult: state.consult,
});

export default connect(mapStateToProps, {
  editConsult,
  getCreatedFor,
  fileUpload,
  getAllTemplate,
  resetUploadState,
})(CreateConsult);
