import moment from "moment";

const getLabel = (data) => {
  let label = data.replace(/_/g, " ");
  return label;
};

const getData = (data) => {
  const createData = (Id, Name, Creation_Date) => {
    return {
      Id,
      Name,
      Creation_Date,
    };
  };
  let dataArray = data.map((specialties) => {
    return createData(
      specialties._id,
      specialties.name,
      specialties.created &&
        specialties.created.createdOn &&
        moment(specialties.created.createdOn).format("MM/DD/YYYY, h:mm:ss A")
    );
  });
  return dataArray;
};

export const heads = (specialties) => {
  let data = getData(specialties);
  let headsArray = [];
  const createHeades = (id, label) => {
    return { id, label };
  };
  data &&
    Object.keys(data[0]).map((data) => {
      headsArray.push(createHeades(data, getLabel(data)));
    });
  return headsArray;
};

export const rows = (specialties) => {
  let data = getData(specialties);
  let rowsArray = [];
  data &&
    data.map((data) => {
      rowsArray.push(data);
    });
  return rowsArray;
};
