import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { List } from "../../../common/list";
import { heads, rows } from "./Data";
import { searchHeads, searchRows } from "./SearchData";

import Spinner from "../../../common/Spinner";

import { TextField, Paper, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getUsersByRole,
  getUsersById,
  resetUserState,
  getUsersByFacility,
} from "../../../../actions/user/user";
import { textSearch } from "../../../../actions/search/Search";

import { Toolbar } from "../../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
}));

const Pcp = ({
  getUsersByRole,
  getUsersById,
  resetUserState,
  getUsersByFacility,
  textSearch,
  showPage,
  user: { users, usersCount, loading },
  authUser,
  search: { searchResults },
}) => {
  useEffect(() => {
    resetUserState();
    if (authUser) {
      if (authUser.role === "sadmin" || authUser.role === "admin") {
        getUsersByRole("pcp", 1, 10);
      }
      if (authUser.role === "fadmin") {
        getUsersByFacility(authUser.facility, 1, 10);
      }
    }
  }, [getUsersByRole]);
  const classes = useStyles();

  const openPcpForm = () => {
    showPage("PcpForm");
  };

  const editById = async (id) => {
    await getUsersById(id);
    showPage("PcpForm", true);
  };

  const changePage = (page, size) => {
    if (authUser) {
      if (authUser.role === "sadmin" || authUser.role === "admin") {
        getUsersByRole("pcp", page, size);
      }
      if (authUser.role === "fadmin") {
        getUsersByFacility(authUser.facility, page, size);
      }
    }
  };

  const changePageSearch = (page, size) => {};

  const [showSearchList, setShowSearchList] = useState(false);

  const onChange = (e) => {
    setShowSearchList(true);
    textSearch("pcp", e.target.value, 1, 10);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={classes.root}>
      <Toolbar
        title="Primary Care Providers"
        button={{
          show: true,
          label: "Create Primary Care Provider",
          click: openPcpForm,
        }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <TextField
            type="text"
            label="Search"
            placeholder="Search"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) =>
              e.target.value ? onChange(e) : setShowSearchList(false)
            }
          />
        </Paper>
      </div>
      {!showSearchList && (
        <div className={classes.content}>
          {users && users.length > 0 ? (
            <List
              heads={heads(users)}
              rows={rows(users)}
              edit={editById}
              changePage={changePage}
              rowsCount={usersCount}
            />
          ) : (
            <Typography variant="h6">No Data</Typography>
          )}
        </div>
      )}
      {showSearchList && (
        <div className={classes.content}>
          {searchResults && searchResults.length > 0 ? (
            <List
              heads={searchHeads(searchResults)}
              rows={searchRows(searchResults)}
              edit={editById}
              changePage={changePageSearch}
              rowsCount={searchResults.length}
            />
          ) : (
            <Typography variant="h6">No Search Data</Typography>
          )}
        </div>
      )}
    </div>
  );
};

Pcp.prototype = {
  user: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  getUsersByRole: PropTypes.func.isRequired,
  getUsersById: PropTypes.func.isRequired,
  resetUserState: PropTypes.func.isRequired,
  getUsersByFacility: PropTypes.func.isRequired,
  textSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.auth.user,
  search: state.search,
});

export default connect(mapStateToProps, {
  getUsersByRole,
  getUsersById,
  resetUserState,
  getUsersByFacility,
  textSearch,
})(Pcp);
