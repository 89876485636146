import { GET_CONSULTS_SUCCESS, GET_CONSULT_SUCCESS } from "../../actions/types";

const initialState = {
  consults: null,
  consultsCount: null,
  consultsType: null,
  consult: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONSULTS_SUCCESS:
      return {
        ...state,
        consults: payload.consult,
        consultsCount: payload.count,
        consultsType: payload.status ? payload.status : null,
        loading: false,
      };
    case GET_CONSULT_SUCCESS:
      return {
        ...state,
        consult: payload,
        loading: false,
      };
    default:
      return state;
  }
}
