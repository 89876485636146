import axios from "axios";

import { GET_CONSULTS_SUCCESS, GET_CONSULT_SUCCESS } from "../types";

import { setAlert } from "../alert/alert";

export const getAllConsult = (page, size) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/consult/${page}/${size}`);
    dispatch({
      type: GET_CONSULTS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getCreatedBy = (status, page, size) => async (dispatch) => {
  try {
    let response = await axios.get(
      `/api/consult/createdBy/${status}/${page}/${size}`
    );

    response.data.status = status;

    dispatch({
      type: GET_CONSULTS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log("err::", err);
    err &&
      err.response &&
      err.response.data.forEach((error) =>
        dispatch(setAlert(error.msg, "error"))
      );
  }
};

export const getCreatedFor = (status, page, size) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/api/consult/createdFor/${status}/${page}/${size}`
    );

    response.data.status = status;

    dispatch({
      type: GET_CONSULTS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log(err.response);
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const createConsult = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post("/api/consult", formData, config);

    if (formData.status === "DRFT") {
      dispatch(setAlert("Consultations Drafted Successfully", "success"));
    } else {
      dispatch(setAlert("Consultations Created Successfully", "success"));
    }
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getConsultById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/consult/${id}`);
    dispatch({
      type: GET_CONSULT_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const editConsult = (formData, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.put(`/api/consult/${id}`, formData, config);
    if (formData.status != "OPEN")
      dispatch(setAlert("Consult Edited Successfully", "success"));
  } catch (err) {
    console.log(err.response);
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};
