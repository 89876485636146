import React, { useRef, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Backdrop,
  Fade,
  Typography,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FileIcon, { defaultStyles } from "react-file-icon";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFacilityById } from "../../../actions/facility/Facility";
import {
  getUsersBySpecialty,
  resetUserState,
} from "../../../actions/user/user";
import { fileUpload, resetUploadState } from "../../../actions/upload/upload";
import { editConsult, getCreatedBy } from "../../../actions/consult/consult";

import { Toolbar } from "../../common/toolbar";
import moment from "moment";
import Pattern from "../../../utils/Pattern";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  errorText: {
    color: "red",
  },
  button: {
    marginTop: theme.spacing(1),
  },
  textEditorError: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    borderColor: "red",
  },
}));

const DraftConsult = ({
  authUser,
  getFacilityById,
  facility: { facility },
  getUsersBySpecialty,
  user: { users },
  fileUpload,
  upload,
  editConsult,
  consult: { consult, consultsType },
  alert,
  resetUserState,
  resetUploadState,
  showPage,
  getCreatedBy,
}) => {
  const classes = useStyles();

  const [buttonDisable, setButtonDisable] = useState(false);

  const [attachmentsUrls, setAttachmentsUrls] = useState([]);

  const [attachmentsNames, setAttachmentsNames] = useState([]);

  const [editorStateDiagnosis, setEditorStateDiagnosis] = useState("");
  const [editorStateConsult, setEditorStateConsult] = useState("");

  useEffect(() => {
    if (alert && alert[0] && alert[0].msg == "Consult Edited Successfully") {
      resetUploadState();
      getCreatedBy(consultsType, 1, 10);
      showPage("ConsultTable");
    } else {
      setButtonDisable(false);
    }
  }, [alert]);

  useEffect(() => {
    authUser && authUser.facility && getFacilityById(authUser.facility);
    consult &&
      consult.consultations &&
      consult.consultations[0] &&
      consult.consultations[0].consultAttachments &&
      consult.consultations[0].consultAttachments.map((item) => {
        setAttachmentsNames((prevState) => [...prevState, item.name]);
      });
    consult &&
      consult.consultations &&
      consult.consultations[0] &&
      consult.consultations[0].consultAttachments &&
      consult.consultations[0].consultAttachments.map((item) => {
        setAttachmentsUrls((prevState) => [...prevState, item.url]);
      });
    consult &&
      consult.consultations &&
      consult.consultations[0] &&
      consult.consultations[0].consult &&
      setEditorStateConsult(consult.consultations[0].consult);

    consult && consult.diagnosis && setEditorStateDiagnosis(consult.diagnosis);
  }, [authUser, getFacilityById]);

  useEffect(() => {
    upload.url &&
      upload.url.length >= 0 &&
      setAttachmentsUrls((prevState) => [...prevState, upload.url[0]]);
  }, [upload]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    errors,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: consult && consult,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "consultations",
  });

  const filePickerRef = useRef([]);

  const pickedHandler = (event) => {
    if (event.target.files && event.target.files.length) {
      const formData = new FormData();
      formData.append("attachments", event.target.files[0]);
      fileUpload(formData);
    }
  };

  const pickImageHandler = (index) => {
    filePickerRef &&
      filePickerRef.current[index] &&
      filePickerRef.current[index].click();
  };

  const getFileExtension = (url) => {
    const fileRegex = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined;
    return fileRegex[0];
  };

  const removeAttachments = (index) => {
    remove(index);
    attachmentsUrls.splice(index, 1);
    attachmentsNames.splice(index, 1);
  };

  const [assignedSpecialist, setAssignedSpecialist] = useState("select");

  const autoAssignSpecialist = () => {
    users && users.length >= 0 && setValue("assignedTo", users[0]._id);
    users && users.length >= 0 && setAssignedSpecialist(users[0]._id);
  };

  const viewSpecialistBio = () => {
    if (getValues("assignedTo")) {
      handleModal();
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const onSubmit = (data) => {
    if (validateForCreateConsult()) {
      setButtonDisable(true);
      editConsult(data, consult._id);
    }
  };

  const validateForCreateConsult = () => {
    let speciality = getValues("speciality");
    let assignedTo = getValues("assignedTo");
    let diagnosis = getValues("diagnosis");
    let consult = getValues("consultations[0].consult");
    let pattern = /^(select)*$/;
    let valid = true;
    if (pattern.test(speciality)) {
      setError("speciality", {
        type: "validate",
        message: "Speciality is required",
      });
      valid = false;
    }
    if (pattern.test(assignedTo)) {
      setError("assignedTo", {
        type: "validate",
        message: "Specialist is required",
      });
      valid = false;
    }
    if (!diagnosis.replace(/(<([^>]+)>)/gi, "")) {
      setError("diagnosis", {
        type: "validate",
        message: "Diagnosis is required",
      });
      valid = false;
    }
    if (!consult.replace(/(<([^>]+)>)/gi, "")) {
      setError("consultations[0].consult", {
        type: "validate",
        message: "Consultation is required",
      });
      valid = false;
    }
    return valid;
  };

  const isFutureDate = () => {
    let dob = getValues("patient.dob");
    dob = moment(dob, "YYYY-MM-DD");
    if (!dob.isValid()) {
      setError("patient.dob", {
        type: "validate",
        message: "Date of Bith is not valid",
      });
      return;
    }
    if (dob > new Date()) {
      setError("patient.dob", {
        type: "validate",
        message: "Future date is not allowed",
      });
      return;
    }
    clearErrors("patient.dob");
  };
  console.log(errors);

  return (
    <div className={classes.root}>
      <Toolbar title="Create Consultation" button={{ show: false }} />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton
              onClick={() => {
                resetUploadState();
                showPage("ConsultTable");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="patient.name.firstName"
                  label="First Name *"
                  placeholder="First Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={
                    errors.patient &&
                    errors.patient.name &&
                    errors.patient.name.firstName
                  }
                  inputRef={register({
                    required: "First Name is required",
                    maxLength: {
                      value: 200,
                      message: "First Name is too long",
                    },
                    pattern: {
                      value: Pattern.name,
                      message: "First Name is not valid",
                    },
                  })}
                />
                {errors.patient &&
                  errors.patient.name &&
                  errors.patient.name.firstName && (
                    <Typography variant="h6" className={classes.errorText}>
                      {errors.patient.name.firstName.message}
                    </Typography>
                  )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="patient.name.lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={
                    errors.patient &&
                    errors.patient.name &&
                    errors.patient.name.lastName
                  }
                  inputRef={register({
                    required: "Last Name is required",
                    maxLength: { value: 200, message: "Last Name is too long" },
                    pattern: {
                      value: Pattern.name,
                      message: "Last Name is not valid",
                    },
                  })}
                />
                {errors.patient &&
                  errors.patient.name &&
                  errors.patient.name.lastName && (
                    <Typography variant="h6" className={classes.errorText}>
                      {errors.patient.name.lastName.message}
                    </Typography>
                  )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="patient.gender"
                  label="Biological Gender *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errors.patient && errors.patient.gender}
                  inputRef={register({
                    required: "Biological Gender is required",
                  })}
                >
                  <option value="">Select Biological Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </TextField>
                {errors.patient && errors.patient.gender && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.patient.gender.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="patient.dob"
                  label="Date of Birth *"
                  placeholder="Date of Birth"
                  fullWidth
                  margin="dense"
                  type="Date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={isFutureDate}
                  inputProps={{
                    min: "1900-01-01",
                    // max: moment(new Date()).format("YYYY-MM-DD"),
                  }}
                  error={errors.patient && errors.patient.dob}
                  inputRef={register({
                    required: "Date of Bith is required",
                  })}
                />
                {errors.patient && errors.patient.dob && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.patient.dob.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="speciality"
                  label="Speciality *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setValue("assignedTo", "select");
                    setAssignedSpecialist("select");
                    resetUserState();
                    e &&
                      e.target &&
                      e.target.value &&
                      e.target.value != "select" &&
                      getUsersBySpecialty(e.target.value);
                  }}
                  error={errors.speciality}
                  inputRef={register()}
                >
                  <option value="select">Select Speciality</option>
                  {facility && facility.specialties
                    ? facility.specialties.map((speciality) => (
                        <option key={speciality._id} value={speciality._id}>
                          {speciality.name}
                        </option>
                      ))
                    : null}
                </TextField>
                {errors.speciality && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.speciality.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  name="assignedTo"
                  label="Specialist *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setAssignedSpecialist(e.target.value);
                  }}
                  error={errors.assignedTo}
                  inputRef={register()}
                >
                  <option value="select">Select Specialist</option>
                  {users &&
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name.title} {user.name.firstName}{" "}
                        {user.name.lastName} {user.name.medicalAbbreviations}
                      </option>
                    ))}
                </TextField>
                {errors.assignedTo && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.assignedTo.message}
                  </Typography>
                )}
              </Grid>
              {users && users.length > 0 && (
                <Grid item md={3} xs={12}>
                  <Button
                    onClick={() => autoAssignSpecialist()}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    Auto Assign Specialist
                  </Button>
                </Grid>
              )}
              {assignedSpecialist !== "select" && (
                <Grid item md={3} xs={12}>
                  <Button
                    className={classes.button}
                    onClick={() => viewSpecialistBio()}
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    View Specialist Bio
                  </Button>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Diagnosis *</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="diagnosis"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.diagnosis}
                  inputRef={register({ required: "Diagnosis is required" })}
                />
                <ReactQuill
                  theme="snow"
                  className={errors.diagnosis && classes.textEditorError}
                  value={editorStateDiagnosis}
                  onChange={(value) => {
                    setEditorStateDiagnosis(value);
                    clearErrors("diagnosis");
                  }}
                />
                {errors.diagnosis && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.diagnosis.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Consultation *</Typography>
              </Grid>
              <input
                name="consultations[0].askedOn"
                ref={register()}
                style={{ display: "none" }}
                type="text"
                value={Date.now()}
              />
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="consultations[0].consult"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={
                    errors.consultations &&
                    errors.consultations[0] &&
                    errors.consultations[0].consult
                  }
                  inputRef={register({ required: "Consultation is required" })}
                />
                <ReactQuill
                  theme="snow"
                  className={
                    errors.consultations &&
                    errors.consultations[0] &&
                    errors.consultations[0].consult &&
                    classes.textEditorError
                  }
                  value={editorStateConsult}
                  onChange={(value) => {
                    setEditorStateConsult(value);
                    clearErrors("consultations[0].consult");
                  }}
                />
                {errors.consultations &&
                  errors.consultations[0] &&
                  errors.consultations[0].consult && (
                    <Typography variant="h6" className={classes.errorText}>
                      {errors.consultations[0].consult.message}
                    </Typography>
                  )}
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="status"
                  inputRef={register()}
                />
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Upload Attachments</TableCell>
                      <TableCell>File Icon</TableCell>
                      <TableCell colSpan="2">Attachment Name</TableCell>
                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <input
                              ref={(el) => (filePickerRef.current[index] = el)}
                              style={{ display: "none" }}
                              type="file"
                              accept=""
                              onChange={pickedHandler}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => pickImageHandler(index)}
                              disabled={attachmentsUrls[index]}
                            >
                              Upload
                            </Button>
                          </TableCell>
                          <TableCell>
                            {attachmentsUrls[index] && (
                              <FileIcon
                                size={50}
                                extension={getFileExtension(
                                  attachmentsUrls[index]
                                )}
                                {...defaultStyles[
                                  getFileExtension(attachmentsUrls[index])
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {attachmentsUrls[index] && (
                              <TextField
                                type="text"
                                name={`consultations[0].consultAttachments[${index}].name`}
                                label="Attachment Name *"
                                placeholder="Attachment Name"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={
                                  attachmentsNames[index] &&
                                  attachmentsNames[index]
                                }
                                error={
                                  errors.consultations &&
                                  errors.consultations[0] &&
                                  errors.consultations[0].consultAttachments &&
                                  errors.consultations[0].consultAttachments[
                                    index
                                  ] &&
                                  errors.consultations[0].consultAttachments[
                                    index
                                  ].name
                                }
                                inputRef={register({
                                  required: "Attachment Name is required",
                                })}
                              />
                            )}
                            {errors.consultations &&
                              errors.consultations[0] &&
                              errors.consultations[0].consultAttachments &&
                              errors.consultations[0].consultAttachments[
                                index
                              ] &&
                              errors.consultations[0].consultAttachments[index]
                                .name && (
                                <Typography
                                  variant="h6"
                                  className={classes.errorText}
                                >
                                  {
                                    errors.consultations[0].consultAttachments[
                                      index
                                    ].name.message
                                  }
                                </Typography>
                              )}
                          </TableCell>
                          <TableCell>
                            {attachmentsUrls[index] && (
                              <TextField
                                type="text"
                                name={`consultations[0].consultAttachments[${index}].url`}
                                label="Attachment URL"
                                placeholder="Attachment URL"
                                style={{ display: "none" }}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={
                                  attachmentsUrls[index]
                                    ? attachmentsUrls[index]
                                    : ""
                                }
                                inputRef={register()}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => removeAttachments(index)}
                              color="primary"
                              variant="contained"
                            >
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Button
                          onClick={() => {
                            append({ name: "", url: "" });
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Add More Attachments
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={buttonDisable}
                  onClick={() => (
                    setValue("status", "PEND"),
                    setValue("diagnosis", editorStateDiagnosis),
                    setValue("consultations[0].consult", editorStateConsult)
                  )}
                >
                  Create Consultation
                </Button>
              </Grid>
            </Grid>
          </form>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={handleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classes.modal}>
                {users &&
                  users.map(
                    (user) =>
                      user._id === getValues("assignedTo") && (
                        <ReactQuill
                          className={classes.textEditor}
                          theme="bubble"
                          readOnly
                          value={user.bio}
                        />
                      )
                  )}
              </div>
            </Fade>
          </Modal>
        </Paper>
      </div>
    </div>
  );
};

DraftConsult.propTypes = {
  authUser: PropTypes.func.isRequired,
  getFacilityById: PropTypes.func.isRequired,
  facility: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getUsersBySpecialty: PropTypes.func.isRequired,
  fileUpload: PropTypes.func.isRequired,
  upload: PropTypes.object.isRequired,
  editConsult: PropTypes.func.isRequired,
  consult: PropTypes.object.isRequired,
  resetUserState: PropTypes.func.isRequired,
  resetUploadState: PropTypes.func.isRequired,
  getCreatedBy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  facility: state.facility,
  user: state.user,
  upload: state.upload,
  consult: state.consult,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getFacilityById,
  getUsersBySpecialty,
  fileUpload,
  editConsult,
  resetUserState,
  resetUploadState,
  getCreatedBy,
})(DraftConsult);
