import { FILE_UPLOAD_SUCCESS, RESET_UPLOAD_STATE } from "../../actions/types";

const initialState = {
  url: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        url: payload,
        loading: false,
      };
    case RESET_UPLOAD_STATE:
      return {
        url: null,
        loading: false,
      };
    default:
      return state;
  }
}
