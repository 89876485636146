import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

//mui
import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/common/theme";
import { SnackbarProvider } from "notistack";

//redux
import { Provider } from "react-redux";
import { store } from "./store";

//views
import { Auth } from "./views/auth";
import { Admin } from "./views/admin";
import { Pcp } from "./views/pcp";
import { Specialist } from "./views/specialist";

//common
import { Alert } from "./components/common/alert";
import {
  PrivateRouteAdmin,
  PrivateRoutePcp,
  PrivateRouteSpecialist,
} from "./components/common/privateRoutes";

//action
import { loadUser } from "./actions/auth/auth";
import setAuthToken from "./utils/setAuthToken";

if (sessionStorage.otpToken) {
  setAuthToken(sessionStorage.otpToken);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Alert />
            <Fragment>
              <Switch>
                <Route exact path="/" component={Auth} />
                <PrivateRouteAdmin exact path="/admin" component={Admin} />
                <PrivateRoutePcp exact path="/pcp" component={Pcp} />
                <PrivateRouteSpecialist
                  exact
                  path="/specialist"
                  component={Specialist}
                />
              </Switch>
            </Fragment>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;
