import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const Toolbar = ({ title, button: { show, label, click } }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Grid
          alignItems="flex-end"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography color="primary" component="h2" variant="h4">
              {title}
            </Typography>
          </Grid>
          {show && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => click()}
              >
                {label}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Toolbar;
