import React from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Link, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  errorText: {
    color: "red",
  },
  link: {
    cursor: "pointer",
  },
}));

const Login = ({ showPage, login, loginAuth, otpAuth, user }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => login(data);

  if (loginAuth === true && otpAuth === false) {
    showPage("OtpPage");
  }

  if (loginAuth === true && otpAuth === true && user) {
    if (user.isFirstLogin) {
      showPage("NewPasswordPage");
    } else {
      switch (user.role) {
        case "sadmin":
        case "admin":
        case "fadmin":
          return <Redirect to="/admin" />;
        case "pcp":
          return <Redirect to="/pcp" />;
        case "specialist":
          return <Redirect to="/specialist" />;
      }
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.title} variant="h2">
        Login
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        label="Username"
        name="username"
        type="text"
        variant="outlined"
        error={errors.username}
        inputRef={register({ required: true })}
      />
      {errors.username && (
        <Typography className={classes.errorText} variant="h6">
          Username is required.
        </Typography>
      )}
      <TextField
        className={classes.textField}
        fullWidth
        label="Password"
        name="password"
        type="password"
        variant="outlined"
        error={errors.password}
        inputRef={register({ required: true })}
      />
      {errors.password && (
        <Typography className={classes.errorText} variant="h6">
          Password is required.
        </Typography>
      )}
      <Button
        className={classes.button}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        Login
      </Button>
      <Typography color="textSecondary" variant="body1">
        <Link
          className={classes.link}
          variant="h6"
          onClick={() => showPage("ForgotPasswordPage")}
        >
          Forgot Password ?
        </Link>
      </Typography>
    </form>
  );
};

Login.prototype = {
  login: PropTypes.func.isRequired,
  loginAuth: PropTypes.bool.isRequired,
  otpAuth: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loginAuth: state.auth.loginAuth,
  otpAuth: state.auth.otpAuth,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
