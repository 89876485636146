import axios from "axios";

import { FILE_UPLOAD_SUCCESS, RESET_UPLOAD_STATE } from "../types";

import { setAlert } from "../alert/alert";

export const fileUpload = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    console.log("it called file upload");
    const response = await axios.post("/api/upload", formData, config);
    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: response.data,
    });
    dispatch(setAlert("File Uploaded Successfully", "success"));
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const resetUploadState = () => async (dispatch) => {
  dispatch({
    type: RESET_UPLOAD_STATE,
  });
};
