import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Toolbar } from "../../common/toolbar";
import { OpenConsult } from "../../common/consult";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Consult = ({ consult: { consult }, showPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar title="View Consultation" button={{ show: false }} />
      <OpenConsult consult={consult} showPage={showPage} page="ConsultTable" />
    </div>
  );
};

Consult.prototype = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  consult: state.consult,
});

export default connect(mapStateToProps)(Consult);
