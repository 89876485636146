import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pattern from "../../utils/Pattern";
import { Toolbar } from "../common/toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addUser, editUser } from "../../actions/user/user";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
}));

const Admin = ({ authUser, editUser, toUpdate, showPage, alert }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, clearErrors } = useForm({
    defaultValues: authUser,
  });
  useEffect(() => {
    alert &&
      alert[0] &&
      alert[0].alertType == "success" &&
      showPage("AdminTable");
  }, [alert]);
  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "notMatched",
        message: "Password and confirm password do not match",
      });
      return;
    } else {
      clearErrors("confirmPassword");
    }
    let formData = {};
    formData.password = data.password;
    formData.isFirstLogin = false;
    editUser(formData, authUser._id);
  };
  console.log(errors);
  return (
    <div className={classes.root}>
      <Toolbar title="Profile" button={{ show: false }} />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("AdminTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name.firstName"
                  label="First Name *"
                  placeholder="First Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name.lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="email.primaryEmail"
                  label="Email Address *"
                  placeholder="Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone Number"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="h6">Create New Password :</Typography>
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.password}
                  inputRef={register({
                    required: true,
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  })}
                />
                {errors.password && errors.password.type === "required" && (
                  <Typography className={classes.errorText} variant="h6">
                    Password is required.
                  </Typography>
                )}
                {errors.password && errors.password.type === "pattern" && (
                  <Typography className={classes.errorText} variant="h6">
                    Password must contain minimum eight characters, at least one
                    letter, one number and one special character.
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.confirmPassword}
                  inputRef={register({
                    required: "Confirm Password is required",
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  })}
                />
                {errors.confirmPassword && (
                  <Typography className={classes.errorText} variant="h6">
                    {errors.confirmPassword.message}
                  </Typography>
                )}
                {errors.confirmPassword &&
                  errors.confirmPassword.type === "pattern" && (
                    <Typography className={classes.errorText} variant="h6">
                      Password must contain minimum eight characters, at least
                      one letter, one number and one special character.
                    </Typography>
                  )}
              </Grid>
              <Grid item md={3} xs={12}>
                <Button color="primary" type="submit" variant="contained">
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Admin.prototype = {
  authUser: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  alert: state.alert,
});

export default connect(mapStateToProps, { editUser })(Admin);
