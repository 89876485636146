import axios from "axios";

import {
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  RESET_USER_STATE,
} from "../types";

import { setAlert } from "../alert/alert";

export const getUsersById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/user/${id}`);
    dispatch({
      type: GET_USER_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getUsersByRole = (role, page, size) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/api/user/roleBy/${role}/${page}/${size}`
    );
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const addUser = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post("/api/user", formData, config);
    dispatch(setAlert("User Added Successfully", "success"));
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const editUser = (formData, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.put(`/api/user/${id}`, formData, config);
    dispatch(setAlert("User Edited Successfully", "success"));
  } catch (err) {
    console.log(err.response);
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const resetUserState = () => async (dispatch) => {
  dispatch({
    type: RESET_USER_STATE,
  });
};

export const getUsersByFacility = (id, page, size) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/api/user/FacilityBy/${id}/${page}/${size}`
    );
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getUsersBySpecialty = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/user/specialtyBy/${id}`);
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};
