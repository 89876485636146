import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import { Layout } from "./layout";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Admin as AdminForm,
  Specialty as SpecialtyForm,
  Specialist as SpecialistForm,
  Facility as FacilityForm,
  FacilityAdmin as FacilityAdminForm,
  Pcp as PcpForm,
  Consult as ConsultForm,
} from "../../components/admin/forms";

import { Admin as AdminTable } from "../../components/admin/tables/admin";
import { Specialty as SpecialtyTable } from "../../components/admin/tables/specialty";
import { Specialist as SpecialistTable } from "../../components/admin/tables/specialist";
import { Facility as FacilityTable } from "../../components/admin/tables/facility";
import { FacilityAdmin as FacilityAdminTable } from "../../components/admin/tables/facilityAdmin";
import { Pcp as PcpTable } from "../../components/admin/tables/pcp";
import { Consult as ConsultTable } from "../../components/admin/tables/consult";

import { Admin as AdminProfile } from "../../components/profile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Admin = ({ user }) => {
  const classes = useStyles();

  const setPageAtSession = () => {
    sessionStorage.setItem("page", 0);
    sessionStorage.setItem("rowsPerPage", 10);
  };

  const [show, setShow] = useState({
    AdminTable: false,
    SpecialtyTable: false,
    SpecialistTable: false,
    FacilityTable: false,
    FacilityAdminTable: false,
    PcpTable: false,
    ConsultTable: false,
    AdminForm: false,
    SpecialtyForm: false,
    SpecialistForm: false,
    FacilityForm: false,
    FacilityAdminForm: false,
    PcpForm: false,
    ConsultForm: false,
    AdminProfile: false,
    toUpdate: false,
  });

  const showPage = (page, toUpdate = false) => {
    switch (page) {
      case "AdminTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: true,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "SpecialtyTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: true,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "SpecialistTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: true,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "FacilityTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: true,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "FacilityAdminTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: true,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "PcpTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: true,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "ConsultTable":
        setPageAtSession();
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: true,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "AdminForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: true,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "SpecialtyForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: true,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "SpecialistForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: true,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "FacilityForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: true,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "FacilityAdminForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: true,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "PcpForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: true,
          ConsultForm: false,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "ConsultForm":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: true,
          AdminProfile: false,
          toUpdate,
        });
        break;
      case "AdminProfile":
        setShow({
          ...show,
          AdminTable: false,
          SpecialtyTable: false,
          SpecialistTable: false,
          FacilityTable: false,
          FacilityAdminTable: false,
          PcpTable: false,
          ConsultTable: false,
          AdminForm: false,
          SpecialtyForm: false,
          SpecialistForm: false,
          FacilityForm: false,
          FacilityAdminForm: false,
          PcpForm: false,
          ConsultForm: false,
          AdminProfile: true,
          toUpdate,
        });
        break;
    }
  };

  useEffect(() => {
    user && user.role && console.log(user.role);
    user && user.role && user.role === "sadmin" && showPage("AdminTable");
    user && user.role && user.role === "admin" && showPage("SpecialtyTable");
    user && user.role && user.role === "fadmin" && showPage("PcpTable");
  }, [user]);

  return (
    <Layout showPage={showPage}>
      <div className={classes.root}>
        <div className={classes.content}>
          {show.AdminTable && <AdminTable showPage={showPage} />}
          {show.SpecialtyTable && <SpecialtyTable showPage={showPage} />}
          {show.SpecialistTable && <SpecialistTable showPage={showPage} />}
          {show.FacilityTable && <FacilityTable showPage={showPage} />}
          {show.FacilityAdminTable && (
            <FacilityAdminTable showPage={showPage} />
          )}
          {show.PcpTable && <PcpTable showPage={showPage} />}
          {show.ConsultTable && <ConsultTable showPage={showPage} />}
          {show.AdminForm && (
            <AdminForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.SpecialtyForm && (
            <SpecialtyForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.SpecialistForm && (
            <SpecialistForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.FacilityForm && (
            <FacilityForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.FacilityAdminForm && (
            <FacilityAdminForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.PcpForm && (
            <PcpForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.ConsultForm && <ConsultForm showPage={showPage} />}
          {show.AdminProfile && <AdminProfile showPage={showPage} />}
        </div>
      </div>
    </Layout>
  );
};

Admin.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Admin);
