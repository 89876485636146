import axios from "axios";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  OTP_SUCCESS,
  OTP_FAIL,
  LOGOUT,
} from "../types";

import { setAlert } from "../alert/alert";
import setAuthToken from "../../utils/setAuthToken";

export const loadUser = () => async (dispatch) => {
  if (sessionStorage.otpToken) {
    setAuthToken(sessionStorage.otpToken);
  }
  try {
    const response = await axios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post("/api/auth/login", formData, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data.token,
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    err.response.status == 500
      ? dispatch(
          setAlert(
            "Unable to connect server, please try after sometime",
            "error"
          )
        )
      : err.response.data.forEach((error) =>
          dispatch(setAlert(error.msg, "error"))
        );
  }
};

export const otp = (formData) => async (dispatch) => {
  if (sessionStorage.loginToken) {
    setAuthToken(sessionStorage.loginToken);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.post("/api/auth/otp", formData, config);
    dispatch({
      type: OTP_SUCCESS,
      payload: response.data.token,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: OTP_FAIL,
    });
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const resendOtp = () => async (dispatch) => {
  if (sessionStorage.loginToken) {
    setAuthToken(sessionStorage.loginToken);
  }
  try {
    await axios.get("/api/auth/resendOtp");
    dispatch(setAlert("OTP Send Successfully", "success"));
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const forgotPassword = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post("/api/auth/forgotPassowrd", formData, config);
    dispatch(
      setAlert(
        "Please login again with the new password sent on your registered email",
        "success"
      )
    );
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const newPassword = (id, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.put(`/api/user/${id}`, formData, config);
    dispatch(setAlert("Password changed Successfully", "success"));
    dispatch(loadUser());
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
