import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pattern from "../../../utils/Pattern";
import States from "../../common/json/States.json";
import Tats from "../../common/json/Tats.json";
import { Toolbar } from "../../common/toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSpecialties } from "../../../actions/specialty/specialty";
import { addFacility, editFacility } from "../../../actions/facility/Facility";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const Facility = ({
  getSpecialties,
  addFacility,
  editFacility,
  toUpdate,
  specialty: { specialties },
  facility: { facility },
  showPage,
  alert,
}) => {
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    getSpecialties();

    alert && alert[0] && alert[0].alertType == "success"
      ? showPage("FacilityTable")
      : setButtonDisable(false);
  }, [getSpecialties, alert]);

  const classes = useStyles();
  const theme = useTheme();
  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues: toUpdate && facility,
  });
  const onSubmit = (data) => {
    setButtonDisable(true);
    data.specialties = selectedSpeciality;
    console.log("data data data", data);
    toUpdate ? editFacility(data, facility._id) : addFacility(data);
  };

  const getSpecialtyIds = () => {
    let defaultValue = [];
    facility.specialties &&
      facility.specialties.length > 0 &&
      facility.specialties.map((specialty) => {
        defaultValue.push(specialty._id);
      });
    return defaultValue;
  };

  const [selectedSpeciality, setSelectedSpeciality] = useState(
    toUpdate ? getSpecialtyIds() : []
  );

  return (
    <div className={classes.root}>
      <Toolbar
        title={toUpdate ? "Update Facility" : "Create Facility"}
        button={{ show: false }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("FacilityTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name"
                  label="Facility Name *"
                  placeholder="Facility Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name}
                  inputRef={register({
                    required: "Facility Name is required",
                    maxLength: {
                      value: 200,
                      message: "Facility Name is too long",
                    },
                    pattern: {
                      value: Pattern.facilityName,
                      message: "Facility Name is not valid",
                    },
                  })}
                />
                {errors.name && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine1"
                  label="Address Line 1 *"
                  placeholder="Address Line 1"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.addressLine1}
                  inputRef={register({
                    required: "Address Line 1 is required",
                    maxLength: {
                      value: 100,
                      message: "Address Line 1 is too long",
                    },
                    pattern: {
                      value: Pattern.address,
                      message: "Address Line 1 is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.addressLine1 && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.addressLine1.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine2"
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.addressLine2}
                  inputRef={register({
                    maxLength: {
                      value: 100,
                      message: "Address Line 2 is too long",
                    },
                    pattern: {
                      value: Pattern.address,
                      message: "Address Line 2 is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.addressLine2 && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.addressLine2.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.suite"
                  label="Suite"
                  placeholder="Suite"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.suite}
                  inputRef={register({
                    maxLength: { value: 200, message: "Suite is too long" },
                  })}
                />
                {errors.address && errors.address.suite && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.suite.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.city"
                  label="City *"
                  placeholder="City"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.city}
                  inputRef={register({
                    required: "City is required",
                    maxLength: { value: 50, message: "City is too long" },
                    pattern: {
                      value: Pattern.city,
                      message: "City is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.city && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.city.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="address.state"
                  label="State *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errors.address && errors.address.state}
                  inputRef={register({ required: "State is required" })}
                >
                  <option value="">Select State</option>
                  {States
                    ? States.map((state) => (
                        <option key={state.label} value={state.label}>
                          {state.label}
                        </option>
                      ))
                    : null}
                </TextField>
                {errors.address && errors.address.state && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.state.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="number"
                  name="address.zipCode"
                  label="Zip Code *"
                  placeholder="Zip Code"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.zipCode}
                  inputRef={register({
                    required: "Zip Code is required",
                    maxLength: { value: 5, message: "Zip Code is too long" },
                    pattern: {
                      value: Pattern.zipCode,
                      message: "Zip Code is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.zipCode && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.zipCode.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <input
                  name="specialties"
                  value={selectedSpeciality}
                  error={errors.specialties}
                  ref={register({
                    required: "Specialties is required",
                  })}
                  style={{ display: "none" }}
                  type="text"
                />
                <TextField
                  name="specialties"
                  label="Specialties *"
                  fullWidth
                  margin="dense"
                  select
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setSelectedSpeciality(e.target.value);
                  }}
                  SelectProps={{
                    multiple: true,
                    value: selectedSpeciality,
                  }}
                >
                  {specialties
                    ? specialties.map((specialty) => (
                        <MenuItem key={specialty._id} value={specialty._id}>
                          {specialty.name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
                {errors.specialties && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.specialties.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="tat"
                  label="Turn Around Time *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errors.tat}
                  inputRef={register({
                    required: "Turn Around Time is required",
                  })}
                >
                  <option value="">Select Turn Around Time</option>
                  {Tats
                    ? Tats.map((tat) => (
                        <option key={tat.id} value={tat.id}>
                          {tat.name}
                        </option>
                      ))
                    : null}
                </TextField>
                {errors.tat && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.tat.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={buttonDisable}
                  variant="contained"
                >
                  {toUpdate ? "Update Facility" : "Create Facility"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Facility.propTypes = {
  getSpecialties: PropTypes.func.isRequired,
  specialty: PropTypes.object.isRequired,
  addFacility: PropTypes.func.isRequired,
  editFacility: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  specialty: state.specialty,
  facility: state.facility,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getSpecialties,
  addFacility,
  editFacility,
})(Facility);
