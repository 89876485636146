import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { Layout } from "./layout";

import { CreateConsult } from "../../components/pcp/forms";
import { Consult as ConsultForm } from "../../components/pcp/forms";
import { DraftConsult } from "../../components/pcp/forms";
import { Consult as ConsultTable } from "../../components/pcp/tables/consult";

import { Pcp as PcpProfile } from "../../components/profile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Pcp = () => {
  const classes = useStyles();

  const [show, setShow] = useState({
    ConsultTable: true,
    ConsultForm: false,
    CreateConsult: false,
    DraftConsult: false,
    PcpProfile: false,
  });

  const showPage = (page) => {
    switch (page) {
      case "ConsultTable":
        setShow({
          ...show,
          ConsultTable: true,
          ConsultForm: false,
          CreateConsult: false,
          DraftConsult: false,
          PcpProfile: false,
        });
        break;
      case "ConsultForm":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: true,
          CreateConsult: false,
          DraftConsult: false,
          PcpProfile: false,
        });
        break;
      case "CreateConsult":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          CreateConsult: true,
          DraftConsult: false,
          PcpProfile: false,
        });
        break;
      case "DraftConsult":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          CreateConsult: false,
          DraftConsult: true,
          PcpProfile: false,
        });
        break;
      case "PcpProfile":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          CreateConsult: false,
          DraftConsult: false,
          PcpProfile: true,
        });
        break;
    }
  };

  return (
    <Layout showPage={showPage}>
      <div className={classes.root}>
        <div className={classes.content}></div>
        {show.ConsultTable && <ConsultTable showPage={showPage} />}
        {show.CreateConsult && <CreateConsult showPage={showPage} />}
        {show.ConsultForm && <ConsultForm showPage={showPage} />}
        {show.DraftConsult && <DraftConsult showPage={showPage} />}
        {show.PcpProfile && <PcpProfile showPage={showPage} />}
      </div>
    </Layout>
  );
};

export default Pcp;
