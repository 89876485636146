import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import {
  AccountCircle,
  Apartment,
  AllInbox,
  LocalHospital,
  AccountBox,
} from "@material-ui/icons";

import Profile from "./Profile";
import SidebarNav from "./SidebarNav";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, showPage, user, className, ...rest } = props;

  const classes = useStyles();

  const getPages = () => {
    let pages;
    switch (user.role) {
      case "sadmin":
        return (pages = [
          {
            title: "Admin",
            id: "AdminTable",
            icon: <AccountCircle />,
          },
          {
            title: "Specialty",
            id: "SpecialtyTable",
            icon: <AccountBox />,
          },
          {
            title: "Specialist",
            id: "SpecialistTable",
            icon: <LocalHospital />,
          },
          {
            title: "Facility / Entity",
            id: "FacilityTable",
            icon: <Apartment />,
          },
          {
            title: "Facility / Entity Admin",
            id: "FacilityAdminTable",
            icon: <AccountCircle />,
          },
          {
            title: "Primary Care Provider",
            id: "PcpTable",
            icon: <AccountBox />,
          },
          {
            title: "All Consultations",
            id: "ConsultTable",
            icon: <AllInbox />,
          },
        ]);
      case "admin":
        return (pages = [
          {
            title: "Specialty",
            id: "SpecialtyTable",
            icon: <AccountBox />,
          },
          {
            title: "Specialist",
            id: "SpecialistTable",
            icon: <LocalHospital />,
          },
          {
            title: "Facility / Entity",
            id: "FacilityTable",
            icon: <Apartment />,
          },
          {
            title: "Facility / Entity Admin",
            id: "FacilityAdminTable",
            icon: <AccountCircle />,
          },
          {
            title: "Primary Care Provider",
            id: "PcpTable",
            icon: <AccountBox />,
          },
          {
            title: "All Consultations",
            id: "ConsultTable",
            icon: <AllInbox />,
          },
        ]);
      case "fadmin":
        return (pages = [
          {
            title: "Primary Care Provider",
            id: "PcpTable",
            icon: <AccountBox />,
          },
        ]);
    }
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile showPage={showPage} />
        <Divider className={classes.divider} />
        {user && user.role && (
          <SidebarNav
            className={classes.nav}
            pages={getPages()}
            showPage={showPage}
          />
        )}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Sidebar);
