import React from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Link, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { otp, resendOtp, logout } from "../../actions/auth/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  resendOtpLink: {
    float: "right",
    cursor: "pointer",
  },
  errorText: {
    color: "red",
  },
  link: {
    cursor: "pointer",
  },
}));

const Otp = ({
  showPage,
  otp,
  resendOtp,
  loginAuth,
  otpAuth,
  user,
  logout,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => otp(data);

  const backToLogin = () => {
    logout();
    showPage("LoginPage");
  };

  if (loginAuth === true && otpAuth === true && user) {
    if (user.isFirstLogin) {
      showPage("NewPasswordPage");
    } else {
      switch (user.role) {
        case "sadmin":
        case "admin":
        case "fadmin":
          return <Redirect to="/admin" />;
        case "pcp":
          return <Redirect to="/pcp" />;
        case "specialist":
          return <Redirect to="/specialist" />;
      }
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.title} variant="h2">
        OTP
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        label="OTP"
        name="otp"
        type="number"
        variant="outlined"
        error={errors.otp}
        inputRef={register({ required: true, pattern: /^\d{4}$/i })}
      />
      {errors.otp && errors.otp.type === "required" && (
        <Typography className={classes.errorText} variant="h6">
          OTP is required.
        </Typography>
      )}
      {errors.otp && errors.otp.type === "pattern" && (
        <Typography className={classes.errorText} variant="h6">
          Enter valid OTP.
        </Typography>
      )}
      <Button
        className={classes.button}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        Login
      </Button>
      <Typography color="textSecondary" variant="body1">
        <Link className={classes.link} variant="h6" onClick={backToLogin}>
          Go back to login
        </Link>
        <Link
          className={classes.resendOtpLink}
          variant="h6"
          onClick={() => resendOtp()}
        >
          Resend OTP
        </Link>
      </Typography>
    </form>
  );
};

Otp.prototype = {
  otp: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  loginAuth: PropTypes.bool.isRequired,
  otpAuth: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loginAuth: state.auth.loginAuth,
  otpAuth: state.auth.otpAuth,
  user: state.auth.user,
});

export default connect(mapStateToProps, { otp, resendOtp, logout })(Otp);
