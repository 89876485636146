import moment from "moment";

const getStatus = (data) => {
  switch (data) {
    case "PEND":
      return "Pending";
    case "RESP":
      return "Responded";
    case "OPEN":
      return "Open";
    case "FPEN":
      return "Pending";
    case "FRES":
      return "Responded";
    case "CLOS":
      return "Closed";
    case "DRFT":
      return "Draft";
  }
};

const getLabel = (data) => {
  let label = data.replace(/_/g, " ");
  return label;
};

const getData = (data) => {
  const createData = (
    Id,
    Consultation_Id,
    Name,
    Gender,
    Date_of_Birth,
    Specialty,
    Specialist,
    Creation_Date,
    Status
  ) => {
    return {
      Id,
      Consultation_Id,
      Name,
      Gender,
      Date_of_Birth,
      Specialty,
      Specialist,
      Creation_Date,
      Status,
    };
  };
  let dataArray = data.map((consult) => {
    return createData(
      consult._id && consult._id,
      consult.refId && consult.refId,
      consult.patient[0].name.firstName +
        " " +
        consult.patient[0].name.lastName,
      consult.patient[0].gender,
      moment(consult.patient[0].dob).format("MM/DD/YYYY"),
      consult.specialty &&
        consult.specialty.length > 0 &&
        consult.specialty[0].name,
      consult.assignedTo &&
        consult.assignedTo.length > 0 &&
        consult.assignedTo[0].name.title +
          " " +
          consult.assignedTo[0].name.firstName +
          " " +
          consult.assignedTo[0].name.lastName +
          " " +
          consult.assignedTo[0].name.medicalAbbreviations,
      moment(consult.created.createdOn).format("MM/DD/YYYY, h:mm:ss A"),
      getStatus(consult.status)
    );
  });
  return dataArray;
};

export const searchHeads = (consult) => {
  let data = getData(consult);
  let headsArray = [];
  const createHeades = (id, label) => {
    return { id, label };
  };
  data &&
    Object.keys(data[0]).map((data) => {
      headsArray.push(createHeades(data, getLabel(data)));
    });
  return headsArray;
};

export const searchRows = (consult) => {
  let data = getData(consult);
  let rowsArray = [];
  data &&
    data.map((data) => {
      rowsArray.push(data);
    });
  return rowsArray;
};
