import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import {
  AllInbox,
  AssignmentLate,
  AssignmentTurnedIn,
  Assignment,
  Drafts,
} from "@material-ui/icons";

import Profile from "./Profile";
import SidebarNav from "./SidebarNav";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, showPage, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: "Inbox New",
      id: "new",
      icon: <AssignmentLate />,
    },
    {
      title: "Inbox Read",
      id: "read",
      icon: <Assignment />,
    },
    {
      title: "Sent",
      id: "sent",
      icon: <Assignment />,
    },
    {
      title: "Closed",
      id: "closed",
      icon: <AssignmentTurnedIn />,
    },
    {
      title: "Draft",
      id: "draft",
      icon: <Drafts />,
    },
    {
      title: "All",
      id: "all",
      icon: <AllInbox />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile showPage={showPage} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} showPage={showPage} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
