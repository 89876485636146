const Pattern = {
  name: /^[.A-Za-z0-9 ,]+$/,
  facilityName: /^[.A-Za-z0-9 &#,/\\-]+$/,
  specialtyName: /^[.A-Za-z0-9 &#,/\\-]+$/,
  medicalAbbr: /^[.A-Za-z0-9, ]+$/,
  mandatoryEmail: /(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  optionalEmail: /^$|(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  zipCode: /^\d{5}$/,
  phone: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
  title: /^[.A-Za-z0-9 ,-]+$/,
  address: /^[.A-Za-z0-9 &#,/\\-]+$/,
  city: /^[.A-Za-z0-9 &#,/\\-]+$/,
  licence: /^[.A-Za-z0-9 &#,/\\-]+$/,
};

module.exports = Pattern;
