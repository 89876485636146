import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import { Layout } from "./layout";

import { ForgotPassword, Login, NewPassword, Otp } from "../../components/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
    textShadow: `0px 0px 5px ${theme.palette.primary.main}`
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const Auth = () => {
  const classes = useStyles();

  const [show, setShow] = useState({
    LoginPage: true,
    ForgotPasswordPage: false,
    OtpPage: false,
    NewPasswordPage: false,
  });

  const showPage = (page) => {
    switch (page) {
      case "ForgotPasswordPage":
        setShow({
          ...show,
          ForgotPasswordPage: true,
          LoginPage: false,
          OtpPage: false,
          NewPasswordPage: false,
        });
        break;
      case "LoginPage":
        setShow({
          ...show,
          ForgotPasswordPage: false,
          LoginPage: true,
          OtpPage: false,
          NewPasswordPage: false,
        });
        break;
      case "OtpPage":
        setShow({
          ...show,
          ForgotPasswordPage: false,
          LoginPage: false,
          OtpPage: true,
          NewPasswordPage: false,
        });
        break;
      case "NewPasswordPage":
        setShow({
          ...show,
          ForgotPasswordPage: false,
          LoginPage: false,
          OtpPage: false,
          NewPasswordPage: true,
        });
        break;
      default:
        setShow({
          ...show,
          ForgotPasswordPage: false,
          LoginPage: true,
          OtpPage: false,
          NewPasswordPage: false,
        });
    }
  };

  return (
    <Layout>
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  eConsult2U Portal
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                {show.LoginPage ? <Login showPage={showPage} /> : null}

                {show.ForgotPasswordPage ? (
                  <ForgotPassword showPage={showPage} />
                ) : null}

                {show.OtpPage ? <Otp showPage={showPage} /> : null}

                {show.NewPasswordPage ? <NewPassword /> : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Auth;
