import { useSnackbar } from "notistack";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) => {
  const { enqueueSnackbar } = useSnackbar();
  alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) =>
      enqueueSnackbar(alert.msg, {
        variant: alert.alertType,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    );
  return null;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
