import React, { useRef, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import FileIcon, { defaultStyles } from "react-file-icon";
import StarRatingComponent from "react-star-rating-component";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fileUpload, resetUploadState } from "../../../actions/upload/upload";
import { editConsult, getCreatedBy } from "../../../actions/consult/consult";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  errorText: {
    color: "red",
  },
  button: {
    marginTop: theme.spacing(1),
  },
  textEditorError: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    borderColor: "red",
  },
  starSize: {
    fontSize: "24pt",
  },
}));

const CreateConsult = ({
  askIndex,
  fileUpload,
  consultId,
  upload,
  alert,
  showPage,
  editConsult,
  resetUploadState,
  getCreatedBy,
  consult: { consultsType },
}) => {
  const classes = useStyles();

  const [buttonDisable, setButtonDisable] = useState(false);

  const [attachmentsUrls, setAttachmentsUrls] = useState([]);

  const setPageAtSession = () => {
    sessionStorage.setItem("page", 0);
    sessionStorage.setItem("rowsPerPage", 10);
  };

  useEffect(() => {
    if (alert && alert[0] && alert[0].msg == "Consult Edited Successfully") {
      setPageAtSession();
      resetUploadState();
      getCreatedBy(consultsType, 1, 10);
      showPage("ConsultTable");
    } else {
      setButtonDisable(false);
    }
  }, [alert]);

  useEffect(() => {
    upload.url &&
      upload.url.length >= 0 &&
      setAttachmentsUrls((prevState) => [...prevState, upload.url[0]]);
    console.log(attachmentsUrls);
  }, [upload]);

  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      consultAttachments: [{ name: "", url: "" }],
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "consultAttachments",
  });

  const filePickerRef = useRef([]);

  const pickedHandler = (event) => {
    if (event.target.files && event.target.files.length) {
      const formData = new FormData();
      formData.append("attachments", event.target.files[0]);
      fileUpload(formData);
    }
  };

  const pickImageHandler = (index) => {
    filePickerRef &&
      filePickerRef.current[index] &&
      filePickerRef.current[index].click();
  };

  const getFileExtension = (url) => {
    const fileRegex = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined;
    return fileRegex[0];
  };

  const removeAttachments = (index) => {
    remove(index);
    attachmentsUrls.splice(index, 1);
  };

  const [editorStateConsult, setEditorStateConsult] = useState("");

  const onSubmit = (data) => {
    if (validateConsult()) {
      setButtonDisable(true);
      if (askIndex == 1) {
        data.status = "FPEN";
      }
      let askkey = `consultations.${askIndex}.consult`;
      let askedonkey = `consultations.${askIndex}.askedOn`;
      let askAttachmentkey = `consultations.${askIndex}.consultAttachments`;

      let formData =
        attachmentsUrls && attachmentsUrls.length > 0
          ? {
              [askkey]: data.consult,
              [askAttachmentkey]: data.consultAttachments.filter(
                (attachment) => attachment.url != ""
              ),
              [askedonkey]: data.askedOn,
              status: data.status,
            }
          : {
              [askkey]: data.consult,
              [askedonkey]: data.askedOn,
              status: data.status,
            };
      editConsult(formData, consultId);
    }
  };

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState("");
  const [feedbackComment, setFeedbackComment] = useState("");

  const handleFeedbackModal = () => {
    setFeedbackModal(!feedbackModal);
  };
  const handleFeedbackRating = (nextValue, prevValue, name) => {
    setFeedbackRating(nextValue);
  };
  const handleFeedbackComment = (event) => {
    setFeedbackComment(event.target.value);
  };

  const validateConsult = () => {
    let consult = getValues("consult");
    if (!consult.replace(/(<([^>]+)>)/gi, "")) {
      setError("consult", {
        type: "validate",
        message: "Consultation is required",
      });
      return false;
    }
    return true;
  };

  const closeConsult = () => {
    let formData = {
      status: "CLOS",
      feedback: {
        rating: feedbackRating,
        comment: feedbackComment,
      },
    };
    editConsult(formData, consultId);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Consultation *</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="consult"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.consult}
                  inputRef={register({ required: "Consultation is required" })}
                />
                <ReactQuill
                  theme="snow"
                  className={errors.consult && classes.textEditorError}
                  value={editorStateConsult}
                  onChange={(value) => {
                    setEditorStateConsult(value);
                    clearErrors("consult");
                  }}
                />
                {errors.consult && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.consult.message}
                  </Typography>
                )}
              </Grid>
              <input
                name="askedOn"
                ref={register}
                style={{ display: "none" }}
                type="text"
                value={Date.now()}
              />
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Upload Attachments</TableCell>
                      <TableCell>File Icon</TableCell>
                      <TableCell colSpan="2">Attachment Name</TableCell>
                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <input
                              ref={(el) => (filePickerRef.current[index] = el)}
                              style={{ display: "none" }}
                              type="file"
                              accept=""
                              onChange={pickedHandler}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => pickImageHandler(index)}
                              disabled={attachmentsUrls[index]}
                            >
                              Upload
                            </Button>
                          </TableCell>
                          <TableCell>
                            {attachmentsUrls[index] && (
                              <FileIcon
                                size={50}
                                extension={getFileExtension(
                                  attachmentsUrls[index]
                                )}
                                {...defaultStyles[
                                  getFileExtension(attachmentsUrls[index])
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`consultAttachments[${index}].name`}
                              style={{
                                display: attachmentsUrls[index]
                                  ? "block"
                                  : "none",
                              }}
                              label="Attachment Name *"
                              placeholder="Attachment Name"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              error={
                                errors.consultAttachments &&
                                errors.consultAttachments[index] &&
                                errors.consultAttachments[index].name
                              }
                              inputRef={register({
                                required: {
                                  value: attachmentsUrls[index] ? true : false,
                                  message: "Attachment Name is required",
                                },
                              })}
                            />
                            {errors.consultAttachments &&
                              errors.consultAttachments[index] &&
                              errors.consultAttachments[index].name && (
                                <Typography
                                  variant="h6"
                                  className={classes.errorText}
                                >
                                  {
                                    errors.consultAttachments[index].name
                                      .message
                                  }
                                </Typography>
                              )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`consultAttachments[${index}].url`}
                              label="Attachment URL"
                              placeholder="Attachment URL"
                              style={{ display: "none" }}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              value={
                                attachmentsUrls[index]
                                  ? attachmentsUrls[index]
                                  : ""
                              }
                              inputRef={register()}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => removeAttachments(index)}
                              color="primary"
                              variant="contained"
                            >
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Button
                          onClick={() => {
                            append({ name: "", url: "" });
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Add More Attachments
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={buttonDisable}
                  variant="contained"
                  onClick={() => setValue("consult", editorStateConsult)}
                >
                  Create Consultation
                </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleFeedbackModal()}
                >
                  Close and Give Feedback
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={feedbackModal}
          onClose={handleFeedbackModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={feedbackModal}>
            <div className={classes.modal}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StarRatingComponent
                    className={classes.starSize}
                    name="feedbackRating"
                    starCount={5}
                    value={feedbackRating}
                    onStarClick={(e) => handleFeedbackRating(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Feedback Comment"
                    margin="dense"
                    name="feedbackComment"
                    variant="outlined"
                    value={feedbackComment}
                    onChange={(e) => handleFeedbackComment(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => closeConsult()}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

CreateConsult.propTypes = {
  upload: PropTypes.object.isRequired,
  editConsult: PropTypes.func.isRequired,
  getCreatedBy: PropTypes.func.isRequired,
  fileUpload: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  resetUploadState: PropTypes.func.isRequired,
  consult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  upload: state.upload,
  alert: state.alert,
  consult: state.consult,
});

export default connect(mapStateToProps, {
  editConsult,
  fileUpload,
  resetUploadState,
  getCreatedBy,
})(CreateConsult);
