import moment from "moment";

const getLabel = (data) => {
  let label = data.replace(/_/g, " ");
  return label;
};

const getData = (data) => {
  const createData = (Id, Name, Address, Creation_Date) => {
    return {
      Id,
      Name,
      Address,
      Creation_Date,
    };
  };
  let dataArray = data.map((facilities) => {
    return createData(
      facilities._id,
      facilities.name,
      facilities.address &&
        facilities.address.city + "," + " " + facilities.address.state,
      moment(facilities.created.createdOn).format("MM/DD/YYYY, h:mm:ss A")
    );
  });
  return dataArray;
};

export const heads = (facilities) => {
  let data = getData(facilities);
  let headsArray = [];
  const createHeades = (id, label) => {
    return { id, label };
  };
  data &&
    Object.keys(data[0]).map((data) => {
      headsArray.push(createHeades(data, getLabel(data)));
    });
  return headsArray;
};

export const rows = (facilities) => {
  let data = getData(facilities);
  let rowsArray = [];
  data &&
    data.map((data) => {
      rowsArray.push(data);
    });
  return rowsArray;
};
