import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  OTP_SUCCESS,
  OTP_FAIL,
  LOGOUT,
} from "../../actions/types";

const initialState = {
  loginToken: sessionStorage.getItem("loginToken"),
  otpToken: sessionStorage.getItem("otpToken"),
  loginAuth: false,
  otpAuth: false,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        loginAuth: true,
        otpAuth: true,
        loading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      sessionStorage.setItem("loginToken", payload);
      return {
        ...state,
        loginToken: payload,
        loginAuth: true,
        otpAuth: false,
        loading: false,
      };
    case OTP_SUCCESS:
      sessionStorage.setItem("otpToken", payload);
      return {
        ...state,
        otpToken: payload,
        loginAuth: true,
        otpAuth: true,
        loading: false,
      };
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case OTP_FAIL:
    case LOGOUT:
      sessionStorage.removeItem("loginToken");
      sessionStorage.removeItem("otpToken");
      return {
        ...state,
        loginToken: null,
        otpToken: null,
        loginAuth: false,
        otpAuth: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
