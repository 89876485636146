import React, { useRef, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSpecialties } from "../../actions/specialty/specialty";
import { editUser } from "../../actions/user/user";
import Titles from "../common/json/Titles.json";
import States from "../common/json/States.json";
import { Toolbar } from "../common/toolbar";
import Pattern from "../../utils/Pattern";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  textEditor: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    height: "100px",
  },
}));

const Specialist = ({
  getSpecialties,
  editUser,
  authUser,
  specialty: { specialties },
  showPage,
  alert,
}) => {
  useEffect(() => {
    getSpecialties();
  }, [getSpecialties]);

  useEffect(() => {
    alert &&
      alert[0] &&
      alert[0].alertType == "success" &&
      showPage("SpecialistTable");
  }, [alert]);

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: authUser,
  });
  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "notMatched",
        message: "Password and confirm password do not match",
      });
      return;
    } else {
      clearErrors("confirmPassword");
    }
    let formData = {};
    formData.password = data.password;
    formData.isFirstLogin = false;
    editUser(formData, authUser._id);
  };

  const filePickerRef = useRef();

  const [profilePicture, setProfilrPicture] = useState(
    authUser && authUser.profilePicture
  );

  const ProfilePictureSubmit = (data) => {
    setProfilrPicture(data);
    let formData = {};
    formData.profilePicture = data;
    editUser(formData, authUser._id);
  };

  const pickedHandler = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      ProfilePictureSubmit(fileReader.result);
    };
    if (event.target.files && event.target.files.length)
      fileReader.readAsDataURL(event.target.files[0]);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const { fields, remove, append } = useFieldArray({
    control,
    name: "licence",
  });

  return (
    <div className={classes.root}>
      <Toolbar title="Profile" button={{ show: false }} />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("ConsultTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <input
                  name="profilePicture"
                  ref={register}
                  style={{ display: "none" }}
                  type="text"
                />
                <input
                  ref={filePickerRef}
                  style={{ display: "none" }}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  onChange={pickedHandler}
                />
                <Avatar
                  alt="Profile Picture"
                  src={profilePicture}
                  className={classes.avatarLarge}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={pickImageHandler}
                >
                  Upload Picture
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  type="text"
                  name="name.title"
                  label="Title"
                  placeholder="Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  type="text"
                  name="name.firstName"
                  label="First Name *"
                  placeholder="First Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  type="text"
                  name="name.lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="name.medicalAbbreviations"
                  label="Medical Abbreviations *"
                  placeholder="Medical Abbreviations"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="email.primaryEmail"
                  label="Primary Email Address *"
                  placeholder="Primary Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="email.secondaryEmail"
                  label="Secondary Email Address"
                  placeholder="Secondary Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone Number"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine1"
                  label="Address Line 1 *"
                  placeholder="Address Line 1"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine2"
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.suite"
                  label="Suite"
                  placeholder="Suite"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.city"
                  label="City *"
                  placeholder="City"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.state"
                  label="State *"
                  placeholder="State"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="number"
                  name="address.zipCode"
                  label="Zip Code *"
                  placeholder="Zip Code"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={register()}
                />
              </Grid>
              {specialties && (
                <Grid item md={6} xs={12}>
                  <TextField
                    type="text"
                    // name="specialty"
                    label="Specialty *"
                    placeholder="Specialty"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={
                      specialties &&
                      specialties
                        .filter(
                          (specialty) => specialty._id === authUser.specialty
                        )
                        .map((specialty) => specialty.name)
                    }
                  />
                </Grid>
              )}

              <Grid item md={12} xs={12}>
                <Typography variant="h6">Bio</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                {authUser && authUser.bio && (
                  <ReactQuill
                    className={classes.textEditor}
                    theme="bubble"
                    readOnly
                    value={authUser.bio}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Licence State</TableCell>
                      <TableCell>Licence Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`licence.${index}.state`}
                              label="Licence State *"
                              placeholder="Licence State"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              inputRef={register()}
                              defaultValue={item.state}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="text"
                              name={`licence.${index}.number`}
                              label="Licence Number *"
                              placeholder="Licence Number"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              inputRef={register()}
                              defaultValue={item.number}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="h6">Create New Password :</Typography>
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.password}
                  inputRef={register({
                    required: true,
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  })}
                />
                {errors.password && errors.password.type === "required" && (
                  <Typography className={classes.errorText} variant="h6">
                    Password is required.
                  </Typography>
                )}
                {errors.password && errors.password.type === "pattern" && (
                  <Typography className={classes.errorText} variant="h6">
                    Password must contain minimum eight characters, at least one
                    letter, one number and one special character.
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.confirmPassword}
                  inputRef={register({
                    required: "Confirm Password is required",
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  })}
                />
                {errors.confirmPassword && (
                  <Typography className={classes.errorText} variant="h6">
                    {errors.confirmPassword.message}
                  </Typography>
                )}
                {errors.confirmPassword &&
                  errors.confirmPassword.type === "pattern" && (
                    <Typography className={classes.errorText} variant="h6">
                      Password must contain minimum eight characters, at least
                      one letter, one number and one special character.
                    </Typography>
                  )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Button color="primary" type="submit" variant="contained">
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Specialist.propTypes = {
  getSpecialties: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  specialty: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  specialty: state.specialty,
  authUser: state.auth.user,
  alert: state.alert,
});

export default connect(mapStateToProps, { getSpecialties, editUser })(
  Specialist
);
