import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import StatusBullet from "../StatusBullet/StatusBullet";

import ListHead from "./ListHead";

import { Avatar } from "@material-ui/core";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 41px",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  profileWidth: {
    width: 10,
  },
}));

const List = ({ heads, rows, changePage, edit, rowsCount }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  let page = parseInt(sessionStorage.getItem("page")) || 0;

  let rowsPerPage = parseInt(sessionStorage.getItem("rowsPerPage")) || 10;

  const handleChangePage = (event, newPage) => {
    sessionStorage.removeItem("page");
    newPage && sessionStorage.setItem("page", newPage);
    page = newPage;
    changePage(page + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.removeItem("page");
    sessionStorage.removeItem("rowsPerPage");
    event.target.value &&
      sessionStorage.setItem("rowsPerPage", event.target.value);
    page = 0;
    rowsPerPage = event.target.value;
    changePage(page + 1, rowsPerPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <ListHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsCount}
              heads={heads}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow
                      hover
                      onClick={() => edit(row.Id, row.Status)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {Object.keys(row).map((key) => {
                        console.log(key, row[key]);
                        if (key != "Id") {
                          if (key === "Turn_Around_Time") {
                            return (
                              <TableCell>
                                <StatusBullet
                                  color={row[key]}
                                  className={classes.statusContainer}
                                  size="lg"
                                />
                              </TableCell>
                            );
                          }
                          if (key === "Profile_Icon") {
                            return (
                              <TableCell className={classes.profileWidth}>
                                <Avatar
                                  className={classes.avatar}
                                  src={row[key]}
                                ></Avatar>
                              </TableCell>
                            );
                          }
                          return <TableCell>{row[key]}</TableCell>;
                        }
                      })}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default List;
