export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAIL = "OTP_FAIL";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const RESET_USER_STATE = "RESET_USER_STATE";

export const GET_SPECIALTIES_SUCCESS = "GET_SPECIALTIES_SUCCESS";
export const GET_SPECIALTY_SUCCESS = "GET_SPECIALTY_SUCCESS";

export const GET_FACILITIES_SUCCESS = "GET_FACILITIES_SUCCESS";
export const GET_FACILITY_SUCCESS = "GET_FACILITY_SUCCESS";

export const GET_CONSULTS_SUCCESS = "GET_CONSULTS_SUCCESS";
export const GET_CONSULT_SUCCESS = "GET_CONSULT_SUCCESS";

export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";

export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const RESET_UPLOAD_STATE = "RESET_UPLOAD_STATE";

export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
