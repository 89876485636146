import { combineReducers } from "redux";

import auth from "./auth/auth";
import alert from "./alert/alert";
import user from "./user/user";
import specialty from "./specialty/specialty";
import facility from "./facility/facility";
import consult from "./consult/consult";
import template from "./template/template";
import upload from "./upload/upload";
import search from "./search/search";

export default combineReducers({
  auth,
  alert,
  user,
  specialty,
  facility,
  consult,
  template,
  upload,
  search,
});
