import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { OpenConsult } from "../../common/consult";
import EditConsult from "./EditConsult";
import CloseConsult from "./CloseConsult";

import { Toolbar } from "../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Consult = ({ consult: { consult }, showPage }) => {
  const classes = useStyles();

  const [askForm, setAskForm] = useState({
    show: false,
    askIndex: "",
    consultId: "",
  });

  const [closeForm, setCloseForm] = useState({
    show: false,
    consultId: "",
  });

  useEffect(() => {
    consult &&
      (consult.status === "RESP" ||
        consult.status === "FRES" ||
        consult.status === "OPEN") &&
      consult.consultations.length <= 1 &&
      setAskForm({
        ...askForm,
        show: true,
        askIndex: consult.consultations.length,
        consultId: consult._id,
      });
    consult &&
      (consult.status === "RESP" ||
        consult.status === "FRES" ||
        consult.status === "OPEN") &&
      consult.consultations.length == 2 &&
      setCloseForm({
        ...closeForm,
        show: true,
        askIndex: consult.consultations.length,
        consultId: consult._id,
      });
  }, [consult]);

  return (
    <div className={classes.root}>
      <Toolbar title="Consultation" button={{ show: false }} />
      <OpenConsult
        consult={consult}
        showPage={showPage}
        page={"ConsultTable"}
      />
      {askForm.show && (
        <EditConsult
          askIndex={askForm.askIndex}
          consultId={askForm.consultId}
          showPage={showPage}
        />
      )}
      {closeForm.show && (
        <CloseConsult consultId={closeForm.consultId} showPage={showPage} />
      )}
    </div>
  );
};

Consult.propTypes = {
  consult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  consult: state.consult,
});

export default connect(mapStateToProps)(Consult);
