import React from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Link, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { forgotPassword } from "../../actions/auth/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  errorText: {
    color: "red",
  },
  link: {
    cursor: "pointer",
  },
}));

const ForgotPassword = ({ showPage, forgotPassword }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => forgotPassword(data);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.title} variant="h2">
        Forgot Password
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        label="Username"
        name="username"
        type="text"
        variant="outlined"
        error={errors.username}
        inputRef={register({ required: true })}
      />
      {errors.username && (
        <Typography className={classes.errorText} variant="h6">
          Username is required.
        </Typography>
      )}
      <Button
        className={classes.button}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        Forgot Password
      </Button>
      <Typography color="textSecondary" variant="body1">
        <Link
          className={classes.link}
          variant="h6"
          onClick={() => showPage("LoginPage")}
        >
          Go back to login
        </Link>
      </Typography>
    </form>
  );
};

ForgotPassword.prototype = {
  forgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { forgotPassword })(ForgotPassword);
