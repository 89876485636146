import moment from "moment";

const getTurnAround = (turnAroundDate, consultCreatedDate) => {
  const futureDate = moment(
    moment(consultCreatedDate).add("hours", turnAroundDate).toDate()
  );
  const myDate = moment().toDate();
  var duration = moment.duration(futureDate.diff(myDate));
  var hours = duration.asHours();
  return getCardColors(Math.round(hours), turnAroundDate);
};

const getCardColors = (differenceHours, originalHours) => {
  const yellowHour = 24;
  const redHour = 0;

  if (differenceHours === originalHours) {
    return "success";
  } else if (differenceHours <= yellowHour && differenceHours >= redHour) {
    return "warning";
  } else if (differenceHours < redHour) {
    return "danger";
  } else {
    return "success";
  }
};

const getStatus = (data) => {
  switch (data) {
    case "PEND":
      return "Pending";
    case "RESP":
      return "Responded";
    case "OPEN":
      return "Open";
    case "FPEN":
      return "Pending";
    case "FRES":
      return "Responded";
    case "CLOS":
      return "Closed";
    case "DRFT":
      return "Draft";
  }
};

const getLabel = (data) => {
  let label = data.replace(/_/g, " ");
  return label;
};

const getData = (data) => {
  const createData = (
    Id,
    Consultation_Id,
    Name,
    Gender,
    Date_of_Birth,
    Primary_Care_Provider,
    Turn_Around_Time,
    Creation_Date,
    Status
  ) => {
    return {
      Id,
      Consultation_Id,
      Name,
      Gender,
      Date_of_Birth,
      Primary_Care_Provider,
      Turn_Around_Time,
      Creation_Date,
      Status,
    };
  };
  let dataArray = data.map((consult) => {
    return createData(
      consult._id && consult._id,
      consult.refId && consult.refId,
      consult.patient[0].name.firstName +
        " " +
        consult.patient[0].name.lastName,
      consult.patient[0].gender,
      moment(consult.patient[0].dob).format("MM/DD/YYYY"),
      consult.created.createdBy[0].name.title +
        " " +
        consult.created.createdBy[0].name.firstName +
        " " +
        consult.created.createdBy[0].name.lastName +
        " " +
        consult.created.createdBy[0].name.medicalAbbreviations,

      consult.facility[0] &&
        consult.facility[0].tat &&
        consult.consultations &&
        consult.consultations[consult.consultations.length - 1].askedOn &&
        getTurnAround(
          consult.facility[0].tat,
          consult.consultations[consult.consultations.length - 1].askedOn
        ),

      moment(consult.created.createdOn).format("MM/DD/YYYY, h:mm:ss A"),
      getStatus(consult.status)
    );
  });
  return dataArray;
};

export const searchHeads = (consult) => {
  let data = getData(consult);
  let headsArray = [];
  const createHeades = (id, label) => {
    return { id, label };
  };
  data &&
    Object.keys(data[0]).map((data) => {
      headsArray.push(createHeades(data, getLabel(data)));
    });
  return headsArray;
};

export const searchRows = (consult) => {
  let data = getData(consult);
  let rowsArray = [];
  data &&
    data.map((data) => {
      rowsArray.push(data);
    });
  return rowsArray;
};
