import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { user, className, showPage, history, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      onClick={() => showPage("SpecialistProfile")}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        src={user && user.profilePicture}
        component={RouterLink}
      />
      <Typography className={classes.name} variant="h6">
        {user &&
          user.name.title +
            " " +
            user.name.firstName +
            " " +
            user.name.lastName +
            "," +
            " " +
            user.name.medicalAbbreviations}
      </Typography>
      <Typography variant="body2">Specialist</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  getPcpProfile: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Profile);
