import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { OpenConsult } from "../../common/consult";
import EditConsult from "./EditConsult";

import { Toolbar } from "../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Consult = ({ consult: { consult }, showPage }) => {
  const classes = useStyles();

  const [responseForm, setresponseForm] = useState({
    show: false,
    responseIndex: "",
    consultId: "",
  });

  useEffect(() => {
    consult &&
      (consult.status === "PEND" || consult.status === "FPEN") &&
      consult.consultations.length <= 2 &&
      setresponseForm({
        ...responseForm,
        show: true,
        responseIndex: consult.consultations.length,
        consultId: consult._id,
      });
  }, [consult]);

  return (
    <div className={classes.root}>
      <Toolbar title="Response Consultation" button={{ show: false }} />
      <OpenConsult
        consult={consult}
        showPage={showPage}
        page={"ConsultTable"}
      />
      {responseForm.show && (
        <EditConsult
          responseIndex={responseForm.responseIndex}
          consultId={responseForm.consultId}
          showPage={showPage}
        />
      )}
    </div>
  );
};

Consult.propTypes = {
  consult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  consult: state.consult,
});

export default connect(mapStateToProps)(Consult);
