import moment from "moment";

const getLabel = (data) => {
  let label = data.replace(/_/g, " ");
  return label;
};

const getData = (data) => {
  const createData = (Id, Name, Email, Phone_Number, Creation_Date) => {
    return {
      Id,
      Name,
      Email,
      Phone_Number,
      Creation_Date,
    };
  };
  let dataArray = data.map((user) => {
    return createData(
      user._id,
      user.name.firstName + " " + user.name.lastName,
      user.email && user.email.primaryEmail,
      user.phone,
      moment(user.created.createdOn).format("MM/DD/YYYY, h:mm:ss A")
    );
  });
  return dataArray;
};

export const heads = (users) => {
  let data = getData(users);
  let headsArray = [];
  const createHeades = (id, label) => {
    return { id, label };
  };
  data &&
    Object.keys(data[0]).map((data) => {
      headsArray.push(createHeades(data, getLabel(data)));
    });
  return headsArray;
};

export const rows = (users) => {
  let data = getData(users);
  let rowsArray = [];
  data &&
    data.map((data) => {
      rowsArray.push(data);
    });
  return rowsArray;
};
