import {
  GET_SPECIALTIES_SUCCESS,
  GET_SPECIALTY_SUCCESS,
} from "../../actions/types";

const initialState = {
  specialties: null,
  specialtiesCount: null,
  specialty: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SPECIALTIES_SUCCESS:
      return {
        ...state,
        specialties: payload.specialty,
        specialtiesCount: payload.count,
        loading: false,
      };
    case GET_SPECIALTY_SUCCESS:
      return {
        ...state,
        specialty: payload,
        loading: false,
      };
    default:
      return state;
  }
}
