import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { List } from "../../../common/list";
import { heads, rows } from "./Data";

import { TextField, Paper, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTemplateById,
  getAllTemplate,
} from "../../../../actions/template/template";
import { textSearch } from "../../../../actions/search/Search";

import { Toolbar } from "../../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
}));

const Template = ({
  getAllTemplate,
  getTemplateById,
  textSearch,
  showPage,
  template: { templates, templatesCount },
  search: { searchResults },
}) => {
  const classes = useStyles();
  const editById = async (id) => {
    await getTemplateById(id);
    showPage("TemplateForm", "true");
  };

  const changePage = (page, size) => {
    getAllTemplate(page, size);
  };

  const [showSearchList, setShowSearchList] = useState(false);

  const onChange = (e) => {
    setShowSearchList(true);
    textSearch("template", e.target.value, 1, 10);
  };

  const openTemplateForm = () => {
    showPage("TemplateForm");
  };

  const changePageSearch = (page, size) => {};

  return (
    <div className={classes.root}>
      <Toolbar
        title="Templates"
        button={{
          show: true,
          label: "Create Templates",
          click: openTemplateForm,
        }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <TextField
            type="text"
            label="Search"
            placeholder="Search"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) =>
              e.target.value ? onChange(e) : setShowSearchList(false)
            }
          />
        </Paper>
      </div>
      {!showSearchList && (
        <div className={classes.content}>
          {templates && templates.length > 0 ? (
            <List
              heads={heads(templates)}
              rows={rows(templates)}
              edit={editById}
              rowsCount={templatesCount}
            />
          ) : (
            <Typography variant="h6">No Data</Typography>
          )}
        </div>
      )}

      {showSearchList && (
        <div className={classes.content}>
          {searchResults && searchResults.length > 0 ? (
            <List
              heads={heads(searchResults)}
              rows={rows(searchResults)}
              changePage={changePage}
              edit={editById}
              changePage={changePageSearch}
              rowsCount={searchResults.length}
            />
          ) : (
            <Typography variant="h6">No Search Data</Typography>
          )}
        </div>
      )}
    </div>
  );
};

Template.prototype = {
  template: PropTypes.object.isRequired,
  getAllTemplate: PropTypes.func.isRequired,
  getTemplateById: PropTypes.func.isRequired,
  textSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  template: state.template,
  search: state.search,
});

export default connect(mapStateToProps, {
  getAllTemplate,
  getTemplateById,
  textSearch,
})(Template);
