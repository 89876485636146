import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Modal,
  Backdrop,
  Fade,
  TextField,
} from "@material-ui/core";

import StarRatingComponent from "react-star-rating-component";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { editConsult, getCreatedBy } from "../../../actions/consult/consult";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },

  errorText: {
    color: "red",
  },
  button: {
    marginTop: theme.spacing(1),
  },
  textEditorError: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    borderColor: "red",
  },
  starSize: {
    fontSize: "24pt",
  },
}));

const CreateConsult = ({
  consultId,
  alert,
  showPage,
  editConsult,
  getCreatedBy,
  consult: { consultsType },
}) => {
  const classes = useStyles();

  const setPageAtSession = () => {
    sessionStorage.setItem("page", 0);
    sessionStorage.setItem("rowsPerPage", 10);
  };

  useEffect(() => {
    if (alert && alert[0] && alert[0].alertType == "success") {
      setPageAtSession();
      getCreatedBy(consultsType, 1, 10);
      showPage("ConsultTable");
    }
  }, [alert]);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState("");
  const [feedbackComment, setFeedbackComment] = useState("");

  const handleFeedbackModal = () => {
    setFeedbackModal(!feedbackModal);
  };
  const handleFeedbackRating = (nextValue, prevValue, name) => {
    setFeedbackRating(nextValue);
  };
  const handleFeedbackComment = (event) => {
    setFeedbackComment(event.target.value);
  };

  const closeConsult = () => {
    let formData = {
      status: "CLOS",
      feedback: {
        rating: feedbackRating,
        comment: feedbackComment,
      },
    };
    editConsult(formData, consultId);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <Grid item md={3} xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleFeedbackModal()}
            >
              Close and Give Feedback
            </Button>
          </Grid>
        </Paper>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={feedbackModal}
          onClose={handleFeedbackModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={feedbackModal}>
            <div className={classes.modal}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StarRatingComponent
                    className={classes.starSize}
                    name="feedbackRating"
                    starCount={5}
                    value={feedbackRating}
                    onStarClick={(e) => handleFeedbackRating(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Feedback Comment"
                    margin="dense"
                    name="feedbackComment"
                    variant="outlined"
                    value={feedbackComment}
                    onChange={(e) => handleFeedbackComment(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => closeConsult()}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

CreateConsult.propTypes = {
  editConsult: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  consult: PropTypes.object.isRequired,
  getCreatedBy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  consult: state.consult,
});

export default connect(mapStateToProps, {
  editConsult,
  getCreatedBy,
})(CreateConsult);
