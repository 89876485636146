import React from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { newPassword } from "../../actions/auth/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0),
  },
  errorText: {
    color: "red",
  },
}));

const NewPassword = ({ newPassword, loginAuth, otpAuth, user }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, clearErrors } = useForm();

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "notMatched",
        message: "Password and confirm password do not match",
      });
      return;
    } else {
      clearErrors("confirmPassword");
    }
    let formData = {};
    formData.password = data.password;
    formData.isFirstLogin = false;
    newPassword(user._id, formData);
  };

  if (loginAuth === true && otpAuth === true && user) {
    if (user.isFirstLogin === false) {
      switch (user.role) {
        case "sadmin":
        case "admin":
        case "fadmin":
          return <Redirect to="/admin" />;
        case "pcp":
          return <Redirect to="/pcp" />;
        case "specialist":
          return <Redirect to="/specialist" />;
      }
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.title} variant="h2">
        New Password
      </Typography>
      <TextField
        className={classes.textField}
        fullWidth
        label="Password"
        name="password"
        type="password"
        variant="outlined"
        error={errors.password}
        inputRef={register({
          required: true,
          pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        })}
      />
      {errors.password && errors.password.type === "required" && (
        <Typography className={classes.errorText} variant="h6">
          Password is required.
        </Typography>
      )}
      {errors.password && errors.password.type === "pattern" && (
        <Typography className={classes.errorText} variant="h6">
          Password must contain minimum eight characters, at least one letter,
          one number and one special character.
        </Typography>
      )}
      <TextField
        className={classes.textField}
        fullWidth
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        variant="outlined"
        error={errors.confirmPassword}
        inputRef={register({
          required: "Confirm Password is required",
          pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        })}
      />
      {errors.confirmPassword && (
        <Typography className={classes.errorText} variant="h6">
          {errors.confirmPassword.message}
        </Typography>
      )}
      {errors.confirmPassword && errors.confirmPassword.type === "pattern" && (
        <Typography className={classes.errorText} variant="h6">
          Password must contain minimum eight characters, at least one letter,
          one number and one special character.
        </Typography>
      )}
      <Button
        className={classes.button}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
      >
        Submit
      </Button>
    </form>
  );
};

NewPassword.prototype = {
  newPassword: PropTypes.func.isRequired,
  loginAuth: PropTypes.bool.isRequired,
  otpAuth: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loginAuth: state.auth.loginAuth,
  otpAuth: state.auth.otpAuth,
  user: state.auth.user,
});

export default connect(mapStateToProps, { newPassword })(NewPassword);
