import React, { useRef, useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createTemplate,
  editTemplate,
} from "../../../actions/template/template";
import { Toolbar } from "../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  textEditorError: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    borderColor: "red",
  },
}));

const Template = ({
  createTemplate,
  editTemplate,
  toUpdate,
  template: { template },
  showPage,
  alert,
}) => {
  useEffect(() => {
    alert &&
      alert[0] &&
      alert[0].alertType == "success" &&
      showPage("TemplateTable");
  }, [alert]);

  const classes = useStyles();
  const { register, handleSubmit, control, setValue, errors } = useForm({
    defaultValues: toUpdate && template,
  });
  const onSubmit = (data) => {
    toUpdate ? editTemplate(data, template._id) : createTemplate(data);
  };

  const [editorTemplateData, setEditorTemplateData] = useState(
    toUpdate ? template.data : ""
  );

  return (
    <div className={classes.root}>
      <Toolbar
        title={toUpdate ? "Update Template" : "Create Template"}
        button={{ show: false }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("TemplateTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  type="text"
                  name="title"
                  label="Template Title *"
                  placeholder="Template Title"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.title && errors.title}
                  inputRef={register({
                    required: "Template Title is required",
                  })}
                />
                {errors.title && errors.title && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.title.message}
                  </Typography>
                )}
              </Grid>

              <Grid item md={12} xs={12}>
                <Typography variant="h6">Template Data:</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="data"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.data}
                  inputRef={register({
                    required: "Template Data is required",
                  })}
                />
                <ReactQuill
                  theme="snow"
                  className={errors.data && classes.textEditorError}
                  value={editorTemplateData}
                  onChange={setEditorTemplateData}
                />
                {errors.data && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.data.message}
                  </Typography>
                )}
              </Grid>

              <Grid item md={6} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setValue("data", editorTemplateData);
                  }}
                >
                  {toUpdate ? "Update Template" : "Create Template"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Template.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  template: state.template,
  alert: state.alert,
});

export default connect(mapStateToProps, { createTemplate, editTemplate })(
  Template
);
