/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCreatedFor } from "../../../../actions/consult/consult";
import { getAllTemplate } from "../../../../actions/template/template";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  label: {
    color: "#ffffff",
    borderRadius: "20px",
    backgroundColor: "#DC143C",
    padding: "1px 5px",
    marginLeft: "9px",
    fontFamily: "roboto",
    fontSize: "11px",
  },
}));

const SidebarNav = (props) => {
  const {
    getCreatedFor,
    getAllTemplate,
    user,
    pages,
    showPage,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  const setPageAtSession = () => {
    sessionStorage.setItem("page", 0);
    sessionStorage.setItem("rowsPerPage", 10);
  };

  const GetConsult = (type) => {
    switch (type) {
      case "all":
        getCreatedFor("ALLnotDRFT", 1, 10);
        setPageAtSession();
        showPage("ConsultTable");
        break;
      case "responded":
        getCreatedFor("RESPnOPEN", 1, 10);
        setPageAtSession();
        showPage("ConsultTable");
        break;
      case "unresponded":
        getCreatedFor("PEND", 1, 10);
        setPageAtSession();
        showPage("ConsultTable");
        break;
      case "template":
        getAllTemplate(1, 10);
        setPageAtSession();
        showPage("TemplateTable");
        break;
    }
  };

  useEffect(() => {
    getCreatedFor("PEND", 1, 10);
  }, [getCreatedFor]);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            onClick={() => GetConsult(page.id)}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  getCreatedFor: PropTypes.func.isRequired,
  getAllTemplate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { getCreatedFor, getAllTemplate })(
  SidebarNav
);
