import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pattern from "../../../utils/Pattern";
import { Toolbar } from "../../common/toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addSpecialty,
  editSpecialty,
} from "../../../actions/specialty/specialty";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
}));

const Specialty = ({
  addSpecialty,
  editSpecialty,
  toUpdate,
  specialty: { specialty },
  showPage,
  alert,
}) => {
  const classes = useStyles();

  const [buttonDisable, setButtonDisable] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: toUpdate && specialty,
  });

  useEffect(() => {
    alert && alert[0] && alert[0].alertType == "success"
      ? showPage("SpecialtyTable")
      : setButtonDisable(false);
  }, [alert]);
  const onSubmit = async (data) => {
    setButtonDisable(true);
    toUpdate
      ? await editSpecialty(data, specialty._id)
      : await addSpecialty(data);
  };

  return (
    <div className={classes.root}>
      <Toolbar
        title={toUpdate ? "Update Specialty" : "Create Specialty"}
        button={{ show: false }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("SpecialtyTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name"
                  label="Specialty Name *"
                  placeholder="Specialty Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name}
                  inputRef={register({
                    required: "Specialty Name is required",
                    maxLength: {
                      value: 200,
                      message: "Specialty Name is too long",
                    },
                    pattern: {
                      value: Pattern.specialtyName,
                      message: "Specialty Name is not valid",
                    },
                  })}
                />
                {errors.name && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={buttonDisable}
                  variant="contained"
                >
                  {toUpdate ? "Update Specialty" : "Create Specialty"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Specialty.prototype = {
  specialty: PropTypes.object.isRequired,
  addSpecialty: PropTypes.func.isRequired,
  editSpecialty: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  specialty: state.specialty,
  alert: state.alert,
});

export default connect(mapStateToProps, { addSpecialty, editSpecialty })(
  Specialty
);
