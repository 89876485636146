import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { List } from "../../../common/list";
import { heads, rows } from "./Data";
import { searchHeads, searchRows } from "./SearchData";

import Spinner from "../../../common/Spinner";

import { TextField, Paper, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getConsultById,
  getCreatedFor,
} from "../../../../actions/consult/consult";
import { textSearch } from "../../../../actions/search/Search";

import { Toolbar } from "../../../common/toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
}));

const Consult = ({
  getCreatedFor,
  getConsultById,
  textSearch,
  showPage,
  consult: { consults, consultsCount, consultsType, loading },
  search: { searchResults },
}) => {
  const classes = useStyles();
  const editById = async (id) => {
    await getConsultById(id);
    showPage("ConsultForm");
  };

  const changePage = (page, size) => {
    switch (consultsType) {
      case "ALLnotDRFT":
        getCreatedFor("ALLnotDRFT", page, size);
        break;
      case "RESPnOPEN":
        getCreatedFor("RESPnOPEN", page, size);
        break;
      case "PEND":
        getCreatedFor("PEND", page, size);
        break;
    }
  };

  const pageTitle = () => {
    switch (consultsType) {
      case "ALLnotDRFT":
        return "All";
        break;
      case "RESPnOPEN":
        return "Responded";
        break;
      case "PEND":
        return "Unresponded";
        break;
    }
  };

  const changePageSearch = (page, size) => {};

  const [showSearchList, setShowSearchList] = useState(false);

  const onChange = (e) => {
    setShowSearchList(true);
    textSearch("specialistConsult", e.target.value, 1, 10, consultsType);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={classes.root}>
      <Toolbar
        title={pageTitle()}
        button={{
          show: false,
        }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <TextField
            type="text"
            label="Search"
            placeholder="Search"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) =>
              e.target.value ? onChange(e) : setShowSearchList(false)
            }
          />
        </Paper>
      </div>
      {!showSearchList && (
        <div className={classes.content}>
          {consults && consults.length > 0 ? (
            <List
              heads={heads(consults)}
              rows={rows(consults)}
              edit={editById}
              changePage={changePage}
              rowsCount={consultsCount}
            />
          ) : (
            <Typography variant="h6">No Data</Typography>
          )}
        </div>
      )}

      {showSearchList && (
        <div className={classes.content}>
          {searchResults && searchResults.length > 0 ? (
            <List
              heads={searchHeads(searchResults)}
              rows={searchRows(searchResults)}
              edit={editById}
              changePage={changePageSearch}
              rowsCount={searchResults.length}
            />
          ) : (
            <Typography variant="h6">No Search Data</Typography>
          )}
        </div>
      )}
    </div>
  );
};

Consult.prototype = {
  consult: PropTypes.object.isRequired,
  getCreatedFor: PropTypes.func.isRequired,
  getConsultById: PropTypes.func.isRequired,
  textSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  consult: state.consult,
  search: state.search,
});

export default connect(mapStateToProps, {
  getCreatedFor,
  getConsultById,
  textSearch,
})(Consult);
