import React, { Fragment } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FileIcon, { defaultStyles } from "react-file-icon";

import { resetUploadState } from "../../../actions/upload/upload";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50%",
  },
  textEditor: {
    borderRadius: "4px",
    border: "1px solid lightgray",
    height: "100px",
  },
}));

const OpenConsult = ({ consult, showPage, page, resetUploadState }) => {
  const classes = useStyles();

  const getFileExtension = (url) => {
    const fileRegex = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined;
    return fileRegex;
  };

  return (
    <div className={classes.content}>
      <Paper className={classes.paper}>
        <div>
          <IconButton
            onClick={() => {
              resetUploadState();
              showPage(page);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              type="text"
              label="Consult Id"
              placeholder="Consult Id"
              variant="outlined"
              margin="dense"
              fullWidth
              value={consult.refId}
            />
          </Grid>
          <Grid item md={6} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <TextField
              type="text"
              label="First Name"
              placeholder="First Name"
              variant="outlined"
              margin="dense"
              fullWidth
              value={consult.patient.name.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              type="text"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              margin="dense"
              fullWidth
              value={consult.patient.name.lastName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              type="text"
              label="Biological Gender"
              placeholder="Biological Gender"
              variant="outlined"
              margin="dense"
              fullWidth
              value={consult.patient.gender}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              type="text"
              label="Date of Birth"
              placeholder="Date of Birth"
              variant="outlined"
              margin="dense"
              fullWidth
              value={moment(consult.patient.dob).format("MM/DD/YYYY")}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="h6">Diagnosis:</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <ReactQuill
              className={classes.textEditor}
              theme="bubble"
              readOnly
              value={consult.diagnosis}
            />
          </Grid>
          {consult.consultations.map((item, index) => {
            return (
              <Fragment>
                <Fragment>
                  {item.consult && (
                    <Fragment>
                      <Grid item md={12} xs={12}>
                        <Typography variant="h6">Consultation:</Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <ReactQuill
                          className={classes.textEditor}
                          theme="bubble"
                          readOnly
                          value={item.consult}
                        />
                      </Grid>
                    </Fragment>
                  )}
                  {item.consultAttachments &&
                    item.consultAttachments.length > 0 && (
                      <Fragment>
                        <Grid item md={12} xs={12}>
                          <Typography variant="h6">
                            Consultation Attachments:
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>File Icon</TableCell>
                                <TableCell>Attachment Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.consultAttachments.map((item, index) => {
                                return (
                                  <TableRow key={item.id}>
                                    <TableCell>
                                      <a href={item.url} download>
                                        <FileIcon
                                          size={50}
                                          extension={getFileExtension(item.url)}
                                          {...defaultStyles[
                                            getFileExtension(item.url)
                                          ]}
                                        />
                                      </a>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        type="text"
                                        label="Attachment Name"
                                        placeholder="Attachment Name"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={item.name}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Fragment>
                    )}
                </Fragment>
                <Fragment>
                  {item.response && (
                    <Fragment>
                      <Grid item md={12} xs={12}>
                        <Typography variant="h6">Response:</Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <ReactQuill
                          className={classes.textEditor}
                          theme="bubble"
                          readOnly
                          value={item.response}
                        />
                      </Grid>
                    </Fragment>
                  )}
                  {item.responseAttachments &&
                    item.responseAttachments.length > 0 && (
                      <Fragment>
                        <Grid item md={12} xs={12}>
                          <Typography variant="h6">
                            Response Attachments:
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>File Icon</TableCell>
                                <TableCell>Attachment Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.responseAttachments.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <a href={item.url} download>
                                        <FileIcon
                                          size={50}
                                          extension={getFileExtension(item.url)}
                                          {...defaultStyles[
                                            getFileExtension(item.url)
                                          ]}
                                        />
                                      </a>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        type="text"
                                        label="Attachment Name"
                                        placeholder="Attachment Name"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={item.name}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Fragment>
                    )}
                </Fragment>
              </Fragment>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
};

OpenConsult.propTypes = {
  resetUserState: PropTypes.func.isRequired,
};

export default connect(null, {
  resetUploadState,
})(OpenConsult);
