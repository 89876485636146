import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { Layout } from "./layout";

import { Consult as ConsultForm } from "../../components/specialist/forms";
import { Consult as ConsultTable } from "../../components/specialist/tables/consult";
import { Template as TemplateTable } from "../../components/specialist/tables/template";
import { Template as TemplateForm } from "../../components/specialist/forms";
import { Specialist as SpecialistProfile } from "../../components/profile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Specialist = () => {
  const classes = useStyles();

  const [show, setShow] = useState({
    ConsultTable: true,
    ConsultForm: false,
    TemplateTable: false,
    TemplateForm: false,
    SpecialistProfile: false,
    toUpdate: false,
  });

  const showPage = (page, toUpdate = false) => {
    switch (page) {
      case "ConsultTable":
        setShow({
          ...show,
          ConsultTable: true,
          ConsultForm: false,
          TemplateTable: false,
          TemplateForm: false,
          SpecialistProfile: false,
        });
        break;
      case "ConsultForm":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: true,
          TemplateTable: false,
          TemplateForm: false,
          SpecialistProfile: false,
        });
        break;
      case "TemplateTable":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          TemplateTable: true,
          TemplateForm: false,
          SpecialistProfile: false,
        });
        break;
      case "TemplateForm":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          TemplateTable: false,
          TemplateForm: true,
          SpecialistProfile: false,
          toUpdate,
        });
        break;
      case "SpecialistProfile":
        setShow({
          ...show,
          ConsultTable: false,
          ConsultForm: false,
          TemplateTable: false,
          TemplateForm: false,
          SpecialistProfile: true,
        });
        break;
    }
  };

  return (
    <Layout showPage={showPage}>
      <div className={classes.root}>
        <div className={classes.content}>
          {show.ConsultTable && <ConsultTable showPage={showPage} />}
          {show.ConsultForm && <ConsultForm showPage={showPage} />}
          {show.TemplateTable && <TemplateTable showPage={showPage} />}
          {show.TemplateForm && (
            <TemplateForm showPage={showPage} toUpdate={show.toUpdate} />
          )}
          {show.SpecialistProfile && <SpecialistProfile showPage={showPage} />}
        </div>
      </div>
    </Layout>
  );
};

export default Specialist;
