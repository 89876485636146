import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pattern from "../../../utils/Pattern";
import { Toolbar } from "../../common/toolbar";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addUser, editUser } from "../../../actions/user/user";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
}));

const Admin = ({
  addUser,
  editUser,
  toUpdate,
  user: { user },
  showPage,
  alert,
}) => {
  const classes = useStyles();

  const [buttonDisable, setButtonDisable] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: toUpdate ? user : { phone: "+1" },
  });
  useEffect(() => {
    alert && alert[0] && alert[0].alertType == "success"
      ? showPage("AdminTable")
      : setButtonDisable(false);
  }, [alert]);
  const onSubmit = (data) => {
    setButtonDisable(true);
    data.role = "admin";
    data.source = "web";
    toUpdate ? editUser(data, user._id) : addUser(data);
  };

  const changeActiveStatus = () => {
    let formData = {};
    formData.isActive = !user.isActive;
    editUser(formData, user._id);
  };

  return (
    <div className={classes.root}>
      <Toolbar
        title={toUpdate ? "Update Admin" : "Create Admin"}
        button={{ show: false }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("AdminTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name.firstName"
                  label="First Name *"
                  placeholder="First Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name && errors.name.firstName}
                  inputRef={register({
                    required: "First Name is required",
                    maxLength: {
                      value: 200,
                      message: "First Name is too long",
                    },
                    pattern: {
                      value: Pattern.name,
                      message: "First Name is not valid",
                    },
                  })}
                />
                {errors.name && errors.name.firstName && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.firstName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="name.lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name && errors.name.lastName}
                  inputRef={register({
                    required: "Last Name is required",
                    maxLength: { value: 200, message: "Last Name is too long" },
                    pattern: {
                      value: Pattern.name,
                      message: "Last Name is not valid",
                    },
                  })}
                />
                {errors.name && errors.name.lastName && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.lastName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="email.primaryEmail"
                  label="Email Address *"
                  placeholder="Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.email && errors.email.primaryEmail}
                  inputRef={register({
                    required: "Email Address is required",
                    maxLength: {
                      value: 320,
                      message: "Email Address is too long",
                    },
                    pattern: {
                      value: Pattern.mandatoryEmail,
                      message: "Email Address is not valid",
                    },
                  })}
                />
                {errors.email && errors.email.primaryEmail && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.email.primaryEmail.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone Number"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.phone}
                  inputRef={register({
                    required: "Phone Number is required",
                    pattern: {
                      value: Pattern.phone,
                      message: "Phone Number is not valid",
                    },
                  })}
                />
                {errors.phone && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.phone.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  color="primary"
                  type="submit"
                  disabled={buttonDisable}
                  variant="contained"
                >
                  {toUpdate ? "Update Admin" : "Create Admin"}
                </Button>
              </Grid>
              {toUpdate && (
                <Grid item md={3} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setButtonDisable(true);
                      changeActiveStatus();
                    }}
                  >
                    {user.isActive ? "Inactivate Admin" : "Activate Admin"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Admin.prototype = {
  user: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  alert: state.alert,
});

export default connect(mapStateToProps, { addUser, editUser })(Admin);
