import axios from "axios";

import { GET_TEMPLATES_SUCCESS, GET_TEMPLATE_SUCCESS } from "../types";

import { setAlert } from "../alert/alert";

export const createTemplate = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post("/api/template", formData, config);
    dispatch(setAlert("Template Created Successfully", "success"));
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const editTemplate = (formData, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.put(`/api/template/${id}`, formData, config);
    dispatch(setAlert("Template Edited Successfully", "success"));
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getTemplateById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/template/${id}`);
    dispatch({
      type: GET_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};

export const getAllTemplate = (page, size) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/template/${page}/${size}`);
    dispatch({
      type: GET_TEMPLATES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    err.response.data.forEach((error) =>
      dispatch(setAlert(error.msg, "error"))
    );
  }
};
