import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Avatar,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFacilities } from "../../../actions/facility/Facility";
import { addUser, editUser } from "../../../actions/user/user";
import Titles from "../../common/json/Titles.json";
import States from "../../common/json/States.json";
import { Toolbar } from "../../common/toolbar";
import Pattern from "../../../utils/Pattern";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "15px",
  },
  errorText: {
    color: "red",
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const Pcp = ({
  getFacilities,
  addUser,
  editUser,
  toUpdate,
  user: { user },
  authUser,
  facility: { facilities },
  showPage,
  alert,
}) => {
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    getFacilities();

    alert && alert[0] && alert[0].alertType == "success"
      ? showPage("PcpTable")
      : setButtonDisable(false);
  }, [getFacilities, alert]);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: toUpdate ? user : { phone: "+1" },
  });
  const onSubmit = (data) => {
    if (validateForBio()) {
      setButtonDisable(true);
      data.role = "pcp";
      data.source = "web";
      toUpdate ? editUser(data, user._id) : addUser(data);
    }
  };

  const validateForBio = () => {
    let bio = getValues("bio");
    let valid = true;
    if (!bio.replace(/(<([^>]+)>)/gi, "")) {
      setError("bio", {
        type: "validate",
        message: "Bio is required",
      });
      valid = false;
    }
    return valid;
  };

  const filePickerRef = useRef();

  const [profilePicture, setProfilrPicture] = useState(
    toUpdate && user && user.profilePicture
  );

  const pickedHandler = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setProfilrPicture(fileReader.result);
    };
    if (event.target.files && event.target.files.length)
      fileReader.readAsDataURL(event.target.files[0]);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };
  const changeActiveStatus = () => {
    let formData = {};
    formData.isActive = !user.isActive;
    editUser(formData, user._id);
  };

  const [editorStateBio, setEditorStateBio] = useState(
    toUpdate ? user.bio : ""
  );

  return (
    <div className={classes.root}>
      <Toolbar
        title={
          toUpdate
            ? "Update Primary Care Provide"
            : "Create Primary Care Provide"
        }
        button={{ show: false }}
      />
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <div>
            <IconButton onClick={() => showPage("PcpTable")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <input
                  name="profilePicture"
                  ref={register}
                  style={{ display: "none" }}
                  type="text"
                />
                <input
                  ref={filePickerRef}
                  style={{ display: "none" }}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  onChange={pickedHandler}
                />
                <Avatar
                  alt="Profile Picture"
                  src={profilePicture}
                  className={classes.avatarLarge}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={pickImageHandler}
                >
                  Upload Picture
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  name="name.title"
                  label="Title"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({ required: true })}
                >
                  {Titles
                    ? Titles.map((title) => (
                        <option key={title.id} value={title.name}>
                          {title.name}
                        </option>
                      ))
                    : null}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  type="text"
                  name="name.firstName"
                  label="First Name *"
                  placeholder="First Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name && errors.name.firstName}
                  inputRef={register({
                    required: "First Name is required",
                    maxLength: {
                      value: 200,
                      message: "First Name is too long",
                    },
                    pattern: {
                      value: Pattern.name,
                      message: "First Name is not valid",
                    },
                  })}
                />
                {errors.name && errors.name.firstName && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.firstName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  type="text"
                  name="name.lastName"
                  label="Last Name *"
                  placeholder="Last Name"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name && errors.name.lastName}
                  inputRef={register({
                    required: "Last Name is required",
                    maxLength: { value: 200, message: "Last Name is too long" },
                    pattern: {
                      value: Pattern.name,
                      message: "Last Name is not valid",
                    },
                  })}
                />
                {errors.name && errors.name.lastName && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.lastName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="name.medicalAbbreviations"
                  label="Medical Abbreviations *"
                  placeholder="Medical Abbreviations"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.name && errors.name.medicalAbbreviations}
                  inputRef={register({
                    required: "Medical Abbreviations is required",
                    maxLength: {
                      value: 100,
                      message: "Medical Abbreviations is too long",
                    },
                    pattern: {
                      value: Pattern.medicalAbbr,
                      message: "Medical Abbreviations is not valid",
                    },
                  })}
                />
                {errors.name && errors.name.medicalAbbreviations && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.name.medicalAbbreviations.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="email.primaryEmail"
                  label="Primary Email Address *"
                  placeholder="Primary Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.email && errors.email.primaryEmail}
                  inputRef={register({
                    required: "Primary Email Address is required",
                    maxLength: {
                      value: 320,
                      message: "Primary Email Address is too long",
                    },
                    pattern: {
                      value: Pattern.mandatoryEmail,
                      message: "Primary Email Address is not valid",
                    },
                  })}
                />
                {errors.email && errors.email.primaryEmail && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.email.primaryEmail.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="email.secondaryEmail"
                  label="Secondary Email Address"
                  placeholder="Secondary Email Address"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.email && errors.email.secondaryEmail}
                  inputRef={register({
                    maxLength: {
                      value: 320,
                      message: "Secondary Email Address is too long",
                    },
                    pattern: {
                      value: Pattern.optionalEmail,
                      message: "Secondary Email Address is not valid",
                    },
                  })}
                />
                {errors.email && errors.email.secondaryEmail && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.email.secondaryEmail.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone Number"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.phone}
                  inputRef={register({
                    required: "Phone Number is required",
                    pattern: {
                      value: Pattern.phone,
                      message: "Phone Number is not valid",
                    },
                  })}
                />
                {errors.phone && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.phone.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine1"
                  label="Address Line 1 *"
                  placeholder="Address Line 1"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.addressLine1}
                  inputRef={register({
                    required: "Address Line 1 is required",
                    maxLength: {
                      value: 100,
                      message: "Address Line 1 is too long",
                    },
                    pattern: {
                      value: Pattern.address,
                      message: "Address Line 1 is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.addressLine1 && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.addressLine1.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.addressLine2"
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.addressLine2}
                  inputRef={register({
                    maxLength: {
                      value: 100,
                      message: "Address Line 2 is too long",
                    },
                    pattern: {
                      value: Pattern.address,
                      message: "Address Line 2 is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.addressLine2 && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.addressLine2.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.suite"
                  label="Suite"
                  placeholder="Suite"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.suite}
                  inputRef={register({
                    maxLength: { value: 200, message: "Suite is too long" },
                  })}
                />
                {errors.address && errors.address.suite && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.suite.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="text"
                  name="address.city"
                  label="City *"
                  placeholder="City"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.city}
                  inputRef={register({
                    required: "City is required",
                    maxLength: { value: 50, message: "City is too long" },
                    pattern: {
                      value: Pattern.city,
                      message: "City is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.city && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.city.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="address.state"
                  label="State *"
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errors.address && errors.address.state}
                  inputRef={register({ required: "State is required" })}
                >
                  <option value="">Select State</option>
                  {States
                    ? States.map((state) => (
                        <option key={state.label} value={state.label}>
                          {state.label}
                        </option>
                      ))
                    : null}
                </TextField>
                {errors.address && errors.address.state && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.state.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="number"
                  name="address.zipCode"
                  label="Zip Code *"
                  placeholder="Zip Code"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.address && errors.address.zipCode}
                  inputRef={register({
                    required: "Zip Code is required",
                    maxLength: { value: 5, message: "Zip Code is too long" },
                    pattern: {
                      value: Pattern.zipCode,
                      message: "Zip Code is not valid",
                    },
                  })}
                />
                {errors.address && errors.address.zipCode && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.address.zipCode.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {facilities && authUser.role != "fadmin" ? (
                  <TextField
                    name="facility"
                    label="Facility *"
                    fullWidth
                    margin="dense"
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={errors.facility}
                    inputRef={register({ required: "Facility is required" })}
                  >
                    <option value="">Select Facility</option>
                    {facilities
                      ? facilities.map((facility) => (
                          <option key={facility._id} value={facility._id}>
                            {facility.name}
                          </option>
                        ))
                      : null}
                  </TextField>
                ) : (
                  <input
                    name="facility"
                    ref={register}
                    style={{ display: "none" }}
                    type="text"
                  />
                )}
                {errors.facility && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.facility.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h6" style={{ marginLeft: "2px" }}>
                  Bio *
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  style={{ display: "none" }}
                  type="text"
                  name="bio"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={errors.bio}
                  inputRef={register({
                    required: "Bio is required",
                  })}
                />
                <ReactQuill
                  theme="snow"
                  className={errors.bio && classes.textEditorError}
                  value={editorStateBio ? editorStateBio : ""}
                  onChange={(value) => {
                    setEditorStateBio(value);
                    clearErrors("bio");
                  }}
                />
                {errors.bio && (
                  <Typography variant="h6" className={classes.errorText}>
                    {errors.bio.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {authUser.role != "fadmin" ? (
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setValue("profilePicture", profilePicture);
                      setValue("bio", editorStateBio);
                    }}
                  >
                    {toUpdate
                      ? "Update Primary Care Provider"
                      : "Create Primary Care Provider"}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setValue("profilePicture", profilePicture);
                      setValue("facility", authUser.facility);
                      setValue("bio", editorStateBio);
                    }}
                  >
                    {toUpdate
                      ? "Update Primary Care Provider"
                      : "Create Primary Care Provider"}
                  </Button>
                )}
              </Grid>
              {toUpdate && (
                <Grid item md={3} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setButtonDisable(true);
                      changeActiveStatus();
                    }}
                  >
                    {user.isActive
                      ? "Inactivate Primary Care Provider"
                      : "Activate Primary Care Provider"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

Pcp.propTypes = {
  getFacilities: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  facility: state.facility,
  user: state.user,
  authUser: state.auth.user,
  alert: state.alert,
});

export default connect(mapStateToProps, { getFacilities, addUser, editUser })(
  Pcp
);
